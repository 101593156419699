import { createReduxAction } from "../../utils/store";

export const SET_GENERIC_DATA_V2_INIT_STATES =
  "SET_GENERIC_DATA_V2_INIT_STATES";
export const SET_APP_CONFIG = "SET_APP_CONFIG";

export const setGenericDataV2InitStates = (payload) =>
  createReduxAction(SET_GENERIC_DATA_V2_INIT_STATES, payload);
export const setAppConfig = (payload) =>
  createReduxAction(SET_APP_CONFIG, payload);
