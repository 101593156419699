import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import FiltersContext from "../../../context/index";
import PostServiceApi from "../../../services/post-service";
import Config from "../../../config";

const { TARGET_ENVIRONMENT, TARGET_ENVIRONMENTS } = Config;

const INTEREST_FILTERS = Object.freeze({
  matches: "matches",
  unmatches: "unmatches",
  "new-matches": "new-matches",
  received: "received",
  sent: "sent",
  spam: "spam",
});

const INTEREST_FILTERS_NAME = {
  [INTEREST_FILTERS.matches]: "Matched",
  [INTEREST_FILTERS.unmatches]: "Unmatched",
  [INTEREST_FILTERS["new-matches"]]: "New Matches",
  [INTEREST_FILTERS.received]: "Received",
  [INTEREST_FILTERS.sent]: "Sent",
  [INTEREST_FILTERS.spam]: "Spam",
};

const isAuthneticated =
  localStorage.getItem("at") &&
  (localStorage.getItem("ur") === "ADMIN" ||
    localStorage.getItem("ur") === "OPERATOR" ||
    localStorage.getItem("ur") === "OPERATOR_L2");

function AllInterestsCom() {
  const [interestType, setInterestType] = useState(
    FiltersContext.getFilterValue() ?? INTEREST_FILTERS.received
  );

  const [page, setPage] = useState(1);
  const [totAds, setTotAds] = useState(0);
  const [loading, setLoading] = useState(true);
  const [interests, setInterests] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage =
    TARGET_ENVIRONMENT === TARGET_ENVIRONMENTS.DEVELOP ? 5 : 25;
  const history = useHistory();

  const querryParams = useLocation().search;
  const id = new URLSearchParams(querryParams).get("id");

  const getMatchedInterests = useCallback(() => {
    const postId = id;
    const searchParams = {
      type: "matched",
      pageNum: page - 1,
      pageSize: itemsPerPage,
    };
    setLoading(true);
    PostServiceApi.getMatchedInterests({
      postId,
      searchParams,
      isAuthneticated,
    })
      .then((res) => {
        setInterests(res.body.results);
        setTotalPages(res.body.pageCount);
        setTotAds(res.body.total);
      })
      .catch((err) => console.log("Error >>>>", err))
      .finally(() => {
        setLoading(false);
      });
  }, [id, page]);

  const getReceivedInterests = useCallback(() => {
    const postId = id;
    const searchParams = {
      pageNum: page - 1,
      pageSize: itemsPerPage,
    };
    setLoading(true);
    PostServiceApi.getReceivedInterests({
      postId,
      searchParams,
      isAuthneticated,
    })
      .then((res) => {
        setInterests(res.body.results);
        setTotalPages(res.body.pageCount);
        setTotAds(res.body.total);
      })
      .catch((err) => {
        console.log("Error >>>>>", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, page]);

  const getSentInterests = useCallback(() => {
    const postId = id;
    const searchParams = {
      pageNum: page - 1,
      pageSize: itemsPerPage,
    };
    setLoading(true);
    PostServiceApi.getSentInterests({
      postId,
      searchParams,
      isAuthneticated,
    })
      .then((res) => {
        setInterests(res.body.results);
        setTotalPages(res.body.pageCount);
        setTotAds(res.body.total);
      })
      .catch((err) => {
        console.log("Error >>>>>", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, page]);

  const getUnmmatchedPosts = useCallback(() => {
    const postId = id;
    const searchParams = {
      type: "unmatched",
      pageNum: page - 1,
      pageSize: itemsPerPage,
    };
    setLoading(true);
    PostServiceApi.getUnmatchedInterests({
      postId,
      searchParams,
      isAuthneticated,
    })
      .then((res) => {
        setInterests(res.body.results);
        setTotalPages(res.body.pageCount);
        setTotAds(res.body.total);
      })
      .catch((err) => console.log("Error >>>>", err))
      .finally(() => {
        setLoading(false);
      });
  }, [id, page]);

  const getNewMatches = useCallback(() => {
    const postId = id;
    const searchParams = {
      pageNum: 1,
      pageSize: itemsPerPage,
    };
    setLoading(true);
    PostServiceApi.getNewMatches({
      postId,
      searchParams,
      isAuthneticated,
    })
      .then((res) => {
        setInterests(res.body);
      })
      .catch((err) => console.log("Error >>>>", err))
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const getSpamInterests = useCallback(() => {
    const postId = id;
    const searchParams = {
      archived: false,
      spam: true,
      pageNum: page - 1,
      pageSize: itemsPerPage,
    };
    setLoading(true);
    PostServiceApi.getReceivedInterests({
      postId,
      searchParams,
      isAuthneticated,
    })
      .then((res) => {
        setInterests(res.body.results);
        setTotalPages(res.body.pageCount);
        setTotAds(res.body.total);
      })
      .catch((err) => {
        console.log("Error >>>>>", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, page]);

  const fetchInterests = useCallback(() => {
    switch (interestType) {
      case INTEREST_FILTERS.received:
        getReceivedInterests();
        break;
      case INTEREST_FILTERS.sent:
        getSentInterests();
        break;
      case INTEREST_FILTERS.matches:
        getMatchedInterests();
        break;
      case INTEREST_FILTERS.unmatches:
        getUnmmatchedPosts();
        break;
      case INTEREST_FILTERS["new-matches"]:
        getNewMatches();
        break;
      case INTEREST_FILTERS.spam:
        getSpamInterests();
        break;
      default:
        return;
    }
  }, [
    interestType,
    getReceivedInterests,
    getSentInterests,
    getMatchedInterests,
    getUnmmatchedPosts,
    getNewMatches,
    getSpamInterests,
  ]);

  useEffect(() => {
    fetchInterests();
  }, [page, interestType, fetchInterests]);

  useEffect(() => {
    const lastSeenPageNum = FiltersContext.getLastSeenPageNumber();
    if (lastSeenPageNum) {
      setPage(parseInt(lastSeenPageNum));
      sessionStorage.removeItem("last-seen-interest-page");
    }
  }, []);

  const styles = {
    firstTwoColumns: {
      padding: "0.3rem",
      border: "none",
      borderRight: "1px solid #dee2e6",
    },
    lastColumn: {
      padding: "0.3rem",
      border: "none",
    },
  };

  return (
    <>
      <div className="content-col" style={{ position: "relative" }}>
        <Breadcrumb mainTitle="Ads" title="Interests" />
        {loading ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div className="row m-0">
              <div
                className="col p-0"
                style={{
                  marginBottom: "-40px",
                  marginTop: "20px",
                  width: "505px",
                }}
              >
                <form
                  style={{
                    display: "block",
                    width: "fit-content",
                    margin: "auto",
                    marginTop: 0,
                  }}
                >
                  <div className="form-row align-items-center">
                    <div className="col-auto">
                      <div
                        className="input-group mb-0"
                        style={{ width: "350px" }}
                      >
                        <select
                          value={interestType}
                          onChange={(e) => {
                            setPage(1);
                            setInterestType(e.target.value);
                          }}
                          className="form-control pr-5"
                        >
                          {Object.keys(INTEREST_FILTERS).map((currentType) => (
                            <option key={currentType} value={currentType}>
                              {INTEREST_FILTERS_NAME[currentType]}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row" style={{ marginTop: "40px" }}>
              <div className="col p-0">
                <div className="pagination-div">
                  {totalPages > 1 && (
                    <Pagination
                      showFirstButton
                      showLastButton
                      count={totalPages}
                      page={page}
                      onChange={(e, value) => setPage(value)}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="container-fluid inner-content pt-0 pb-4">
              <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
                <p style={{ fontSize: 14, marginBottom: 10 }}>
                  {interests.length > 0
                    ? page * itemsPerPage > totAds
                      ? `Showing ${
                          (page - 1) * itemsPerPage
                        } - ${totAds} out of ${totAds} results`
                      : `Showing ${(page - 1) * itemsPerPage} - ${
                          page * itemsPerPage
                        } out of ${totAds} results`
                    : "No results"}
                </p>
                <div className="content p-0">
                  <table
                    className="table table-hover table-responsive common-table border mb-0"
                    style={{ fontSize: 14 }}
                  >
                    <thead className="header">
                      <tr>
                        <th className="border-0" width="230">
                          Created Date
                        </th>
                        <th className="border-0" width="150">
                          Ad ID
                        </th>
                        <th className="border-0" width="150">
                          Display Name
                        </th>
                        <th className="border-0" width="350">
                          Time Line
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {interests.length ? (
                        interests.map((el, i) => (
                          <tr
                            key={i}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              FiltersContext.setLastSeenPageNumber(page);
                              FiltersContext.setInterestType(interestType);
                              el.post &&
                                history.push(`/post?id=${el.post?.id}`);
                            }}
                          >
                            <td width="200">
                              {el.idVerified ? (
                                <i
                                  style={{
                                    color: "#2979ff",
                                    fontSize: "12px",
                                    marginLeft: "6px",
                                  }}
                                  className="fas fa-check-circle"
                                ></i>
                              ) : null}
                              <p className="mb-0" style={{ width: 200 }}>
                                {moment
                                  .utc(el.matchedAt ? el.matchedAt : el.likedAt)
                                  .local()
                                  .format("DD-MMM-YYYY hh:mm a")}
                              </p>
                            </td>
                            <td width="150">{el.post && el.post.id}</td>
                            <td width="150">
                              {el.post && el.post.personalInfo.displayName}
                            </td>
                            <td width="380">
                              <table>
                                {el.timeline.map((data, i) => (
                                  <tr>
                                    <td style={styles.firstTwoColumns} key={i}>
                                      {data.eventType
                                        .toString()
                                        .charAt(0)
                                        .toUpperCase() +
                                        data.eventType
                                          .toString()
                                          .slice(1)
                                          .toLowerCase() +
                                        " by"}
                                    </td>
                                    <td style={styles.firstTwoColumns} key={i}>
                                      {(
                                        data.actionBy
                                          .toString()
                                          .charAt(0)
                                          .toUpperCase() +
                                        data.actionBy
                                          .toString()
                                          .slice(1)
                                          .toLowerCase()
                                      ).replace(/_/g, " ")}
                                    </td>
                                    <td style={styles.lastColumn} key={i}>
                                      {moment
                                        .utc(data.eventTime)
                                        .local()
                                        .format("DD-MMM-YYYY hh:mm a")}
                                    </td>
                                  </tr>
                                ))}
                              </table>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3" width="480">
                            No Records
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row m-0">
                <div className="col p-0">
                  <div className="pagination-div">
                    {totalPages > 1 && (
                      <Pagination
                        showFirstButton
                        showLastButton
                        count={totalPages}
                        page={page}
                        onChange={(e, value) => setPage(value)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AllInterestsCom;
