import { Grid, Typography } from "@material-ui/core";
import React from "react";

const TableHead = () => {
  return (
    <Grid container direction="row" className="table-head table-row">
      <Grid item xs={2} container className="justify-center items-center">
        <Typography>Criteria</Typography>
      </Grid>
      <Grid item xs container direction="column">
        <Grid item container direction="row">
          <Grid item xs={2} container className="justify-center items-center">
            <Typography>Package</Typography>
          </Grid>
          <Grid
            item
            xs={5}
            container
            direction="column"
            className="justify-center items-center"
          >
            <Typography className="flex-1 w-full">Card Payments</Typography>
            <Grid item className="flex-1 w-full" container direction="row">
              <Typography className="flex-1 w-full">OneTime</Typography>
              <Typography className="flex-1 w-full">Subscription</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            container
            direction="column"
            className="justify-center items-center"
          >
            <Typography className="flex-1 w-full">Cash Payments</Typography>
            <Grid item className="flex-1 w-full" container direction="row">
              <Typography className="flex-1 w-full">OneTime</Typography>
            </Grid>
          </Grid>
          <Grid item xs container className="justify-center items-center">
            <Typography align="center">Total</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TableHead;
