import PostServiceApi from "../../services/post-service";
import { isEmpty } from "lodash";
import UserApi from "../../services/user-service";

export const getUserAccount = async ({ params }) => {
  try {
    const response = await PostServiceApi.getUserAccount({ params });
    if (!response.success) {
      throw new Error("Unable to get account");
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const cancelSubscription = async ({ postId }) => {
  try {
    const responce = await PostServiceApi.cancelSubscription({ postId });
    if (!responce.success) {
      throw new Error("Unable to Cancel Subscription");
    }
    return responce;
  } catch (error) {
    throw error;
  }
};

export const findPostAddress = async ({ search }) => {
  if (!(!isEmpty(search) && typeof search === "string")) {
    throw new Error("Invalid search provided");
  }
  const paramKey = search.length === 7 ? "extId" : "offlineId";
  const searchParams = { [paramKey]: search };
  const response = await PostServiceApi.getAddressByPost({ searchParams });
  return response;
};

export const saveUserOfflineRequestType = async ({
  memberId,
  offlineRequestType,
}) => {
  if (isEmpty(offlineRequestType) || typeof offlineRequestType !== "string") {
    return;
  }
  const response = UserApi.updateUserPreferences({
    memberId,
    preferences: { offlineRequestType },
  });
  return response;
};
