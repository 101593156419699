import React, { useState } from "react";

import { isEmpty } from "lodash";
import { Typography } from "@material-ui/core";

import Close from "../../../images/close-white.png";
import { SUBSCRIPTION_STATUS_INFO } from "../../../static-data";

function AdPublishModal(props) {
  const [packageError, setPackageError] = useState("");
  const [banktransfer, setBankTransfer] = useState(props.status == 'PAYMENT_PENDING');
  const [currency, setCurrency ] = useState(null)
  const [amount, setAmount ] = useState(null)

  const publishAd = () => {
    if (props.isOverRidePackage) {
      if (!props.selectedPackage) {
        setPackageError('Select a package');
      } else {
        setPackageError("");
        props.handlePublish();
      }
    } else {
      setPackageError("");
      props.handlePublish();
    }
  }

  const selectPackage = (id) => {
    setPackageError("");
    props.changeSelectedPackage(id);
  }

  const selectCurrency = (id) => {
    setCurrency(id);
    props.changeSelectedCurrency(id);
  }

  const selectAmount = (id) => {
    setAmount(id);
    props.changeSelectedAmount(id);
  }

  const handleBankTransfer = (e) => {
    setBankTransfer(e.target.checked)
    setAmount("");
    setCurrency("");
  }
  const subscriptionStatus = props.subscriptionStatus;
  const subscriptionStatusInfo =
    SUBSCRIPTION_STATUS_INFO.find(
      (status) => status.id === subscriptionStatus
    ) ?? {};
  return (
    <div
      className="modal fade"
      id="adPublishModal"
      tabIndex="-1"
      aria-labelledby="adPublishModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="adPublishModalLabel">
              Are you sure?
            </h5>
            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              width="50px"
              style={{ cursor: "pointer" }}
              id="adPublishModalClose"
            />
          </div>
          <div className="modal-body">
            {(props.status === "PAYMENT_PENDING" || props.status === "IN_REVIEW") &&
              <>
                <div className="form-group mb-0" style={{display : ""}}>
                  <label className="col-md-6 px-md-0 px-sm-2 mb-1" style={{display : "flex", alignItems: 'center'}}>
                    Package
                  </label>
                  <select
                    className="form-control col-md-8 px-sm-2 mb-1"
                    id="package"
                    name="package"
                    value={props.selectedPackage}
                    // onChange={(e) => props.changeSelectedPackage(e.target.value)}
                    onChange={(e) => selectPackage(e.target.value)}
                    disabled={!props.isOverRidePackage}
                    style={!props.isOverRidePackage ? {color:'#656565'} : {color: '#333333'}}
                  >
                    <option defaultValue hidden>
                    -- Select --
                    </option>
                    {props.packages?.map((el) => (
                          <option value={el.id} key={el.id}>
                            {el.id}
                          </option>
                        ))}
                  </select>
                  {!isEmpty(subscriptionStatusInfo) && (
                    <Typography
                      className={`subscription-status-${subscriptionStatusInfo.labelType}`}
                      variant="caption"
                    >
                      {subscriptionStatusInfo.labelName}
                    </Typography>
                  )}
                  </div>
                <div class="custom-control custom-checkbox mt-2 mb-3">
              <input
                type="checkbox"
                class="custom-control-input"
                id="override"
                checked={banktransfer}
                onChange={(e)=>handleBankTransfer(e)}
              />
              <label class="custom-control-label" for="override" style={{display: 'flex', alignItems: 'center'}}>
                 Bank Transaction
              </label>
            </div>
            {banktransfer && 
            <div>
              <div className="form-group" style={{ marginBottom: 0}}>
                <label className="col-md-6 px-md-0 px-sm-2 mb-1" style={{display : "flex", alignItems: 'center'}}>
                  Payment Currency *
                </label>
                <select
                  className="form-control col-md-8 px-sm-2 mb-1"
                  id="currency"
                  name="currency"
                  value={props.selectCurrency}
                  // onChange={(e) => props.changeSelectedPackage(e.target.value)}
                  onChange={(e) => selectCurrency(e.target.value)}
                  disabled={!props.isOverRidePackage}
                  style={!props.isOverRidePackage ? {color:'#656565'} : {color: '#333333'}}
                >
                  <option defaultValue hidden>
                    -- Select --
                  </option>
                  <option value="USD">USD</option>
                  <option value="LKR">LKR</option>
                </select>
              </div>
              <div className="form-group" style={{ marginBottom: 0}}>
                <label className="col-md-6 px-md-0 px-sm-2 mb-1" style={{display : "flex", alignItems: 'center'}}>
                  Payment Amount *
                </label>
                <input onChange={(e) => selectAmount(e.target.value)} className="form-control col-md-8 px-sm-2 mb-1" type="number" />
              </div>
            </div>}
                <div className="package-error mb-3" style={{color: '#dc3545', fontSize: '80%'}}>{packageError}</div>
                {props.defaultPackage &&
                  <div class="custom-control custom-checkbox mt-1 mb-3">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="override"
                      checked={props.isOverRidePackage}
                      onChange={(e) => props.overRidePackage(e.target.checked)}
                    />
                    <label class="custom-control-label" for="override" style={{display: 'flex', alignItems: 'center'}}>
                      Override the package
                    </label>
                  </div>
                }
                </>
              }
            <p style={{ marginBottom: 25 }}>Do you want to publish this ad?</p>
          </div>
          <div className="modal-footer border-0 p-2">
            <button type="button" className="btn" data-dismiss="modal">
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={publishAd}
              style={{ width: 95 }}
              disabled={banktransfer && (!currency || !amount)}
            >
              {props.loading ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Publish"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdPublishModal;
