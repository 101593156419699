import httpAdapter, {
  ApiVersion,
  ContentType,
  HttpMethod,
} from "../../utils/http-adaper";

const UserApi = {
  updateUserPreferences: async ({ memberId, preferences }) => {
    const response = await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: `admin/user/${memberId}/preferences`,
      authneticated: true,
      body: preferences,
      contentType: ContentType.JSON,
    });
    return response;
  },
};

export default UserApi;
