import { Grid, Typography } from "@material-ui/core";
import React from "react";

const GrandTotalSumRow = ({ data, label }) => {
  return (
    <Grid container direction="row" className="table-row table-footer-row">
      <Grid item xs={2} container className="justify-center items-center">
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xs container direction="column">
        <Grid item container className="justify-center items-center">
          <Grid item xs={2} className="outer-cell" />
          <Grid
            item
            xs={5}
            container
            direction="row"
            className="justify-center items-center outer-cell divided-cell"
          >
            <Typography className="flex-1 w-full">
              {data.card.oneTime ?? 0}
            </Typography>
            <Typography className="flex-1 w-full">
              {data.card.subscription ?? 0}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            container
            direction="row"
            className="justify-center items-center outer-cell"
          >
            <Typography className="flex-1 w-full">
              {data.cash.oneTime}
            </Typography>
          </Grid>
          <Grid
            item
            xs
            container
            className="justify-center items-center outer-cell"
          >
            <Typography align="center">{data.total}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const SubTotalSubmRow = ({ data, label }) => {
  return (
    <Grid
      item
      container
      className="table-stat-row justify-center items-center table-sub-total-row"
    >
      <Grid item xs={2} className="outer-cell">
        <Typography>{label}</Typography>
      </Grid>
      <Grid
        item
        xs={5}
        container
        direction="row"
        className="justify-center items-center outer-cell divided-cell"
      >
        <Typography className="flex-1 w-full">{data.card.oneTime}</Typography>
        <Typography className="flex-1 w-full">
          {data.card.subscription}
        </Typography>
      </Grid>
      <Grid
        item
        xs={3}
        container
        direction="row"
        className="justify-center items-center outer-cell"
      >
        <Typography className="flex-1 w-full">{data.cash.oneTime}</Typography>
      </Grid>
      <Grid item xs container className="justify-center items-center">
        <Typography className="flex-1 w-full text-bolder">
          {data.total}
        </Typography>
      </Grid>
    </Grid>
  );
};

/**
 *
 * @param varaint => either oneOf("subTotal", "grandTotal")
 *
 * @returns
 */
const TableSumRow = ({ variant, data, label }) => {
  if (variant === "grandTotal") {
    return <GrandTotalSumRow data={data} label={label} />;
  }
  return <SubTotalSubmRow data={data} label={label} />;
};

export default TableSumRow;
