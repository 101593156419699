import {
  Grid,
  makeStyles,
  TableCell,
  TableHead,
  withStyles,
} from "@material-ui/core";
import React from "react";
import Breadcrumb from "../../components/admin/breadcrumb/Breadcrumb";

const useClasses = makeStyles({
  contentContainer: {
    padding: 32,
    rowGap: 16,
  },
});

// Reusable Component -> Content Region Template - Refer HTML5 Regions
const PageContentLayout = ({ children, heading = "Page Heading" }) => {
  const classes = useClasses();

  return (
    <Grid container direction="column">
      <Breadcrumb title={heading} />
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.contentContainer}
      >
        {children}
      </Grid>
    </Grid>
  );
};

const StyledTableCell = withStyles(() => ({
  root: {
    textAlign: "center",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableHead = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}))(TableHead);

const StyledHeaderCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
    textAlign: "center",
    textTransform: "capitalize",
  },
}))(TableCell);

export default {
  PageContentLayout,
  TableHeadCell: StyledHeaderCell,
  TableHead: StyledTableHead,
  StyledTableCell,
};
