import {
  setAppConfig,
  setGenericDataV2InitStates,
} from "../../actions/generic-data";
import GenericApi from "../../services/generics";

export const getVipPackages = async ({ page = 0, limit = 25 }) => {
  const response = await GenericApi.getVipRequests({ page, limit });
  return response;
};

export const initializeGenericData = () => async (dispatch) => {
  const nextState = {};

  let response = await GenericApi.getGenericFilters();
  if (!response.success) {
    return;
  }

  const sortedReligions = response.body.religions.sort(
    (a, b) => a.sortOrder - b.sortOrder
  );

  const sortedEthnicities = response.body.ethnicities.sort(
    (a, b) => a.sortOrder - b.sortOrder
  );

  const sortedMaritalStatuses = response.body.civilStatuses.sort(
    (a, b) => a.sortOrder - b.sortOrder
  );

  const residentCountries = response.body.countries.filter(
    (el) => el.code !== "ZZ"
  );

  const religions = sortedReligions.filter((el) => {
    return el.id !== "other" && el.id !== "none";
  });

  const ethnicities = sortedEthnicities.filter((el) => {
    return el.id !== "other";
  });

  const maritalStatuses = sortedMaritalStatuses.filter((el) => {
    return el.id !== "other";
  });

  const genericFilters = {
    religions,
    ethnicities,
    maritalStatuses,
    residentCountries,
  };

  response = await GenericApi.getPricingPackages({ showNew: false });
  if (!response.success || !Array.isArray(response.body)) {
    return;
  }

  const oldPackages = response.body.map((current) => ({
    ...current,
    oldPackage: true,
  }));

  response = await GenericApi.getPricingPackages({ showNew: true });
  if (!response.success || !Array.isArray(response.body)) {
    return;
  }

  nextState.pricingPackages = [...oldPackages, ...response.body];
  nextState.initialized = true;
  nextState.genericFilters = genericFilters;
  dispatch(setGenericDataV2InitStates(nextState));
};

export const initializeConfig = () => async (dispatch) => {
  const responce = await GenericApi.getAppConfig();
  if (responce.success) {
    dispatch(setAppConfig(responce.body));
  }
};
