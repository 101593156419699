import React, { useCallback, useEffect, useMemo, useState } from "react";
import Breadcrumb from "../breadcrumb/Breadcrumb";

import {
  findPostAddress,
  getUserAccount,
  saveUserOfflineRequestType,
} from "../../../features/post";
import { isEmpty, isObject } from "lodash";
import {
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Button,
} from "@material-ui/core";

import "./style.css";

const OFFLINE_REQUEST_TYPES = [
  { value: "POST", label: "post" },
  { value: "WHATSAPP", label: "whatsapp" },
  { value: "EMAIL", label: "email" },
];

const OfflineRequestTypeForm = ({ post, memberId }) => {
  const [userAccount, setUserAccount] = useState({});
  const [value, setValue] = useState("");
  const [progressing, setProgressing] = useState(true);

  const getAccount = useCallback(async ({ memberId }) => {
    try {
      setProgressing(true);
      const response = await getUserAccount({ params: { memberId } });
      if (!response.success) {
        throw new Error(`HTTP ERROR: ${response.statusCode}`);
      }
      setUserAccount(response.body);
    } catch (error) {
      // TODO: Error Handling
      console.log("[Error!] OfflineRequestTypeForm getUserAccount ", error);
    } finally {
      setProgressing(false);
    }
  }, []);

  const onCLickCancelBtn = useCallback(() => {
    const offlineRequestType =
      userAccount.userPreferences?.offlineRequestType ?? "";
    setValue(offlineRequestType);
  }, [userAccount]);

  const onClickSaveBtn = useCallback(async () => {
    try {
      setProgressing(true);
      if (!isEmpty(post) && isObject(post) && post.memberId) {
        const response = await saveUserOfflineRequestType({
          memberId: post.memberId,
          offlineRequestType: value,
        });
        if (response.success) {
          getAccount({ memberId: post.memberId });
        } else {
          // TODO: Error Handling
        }
      }
    } catch (error) {
    } finally {
      setProgressing(false);
    }
  }, [getAccount, post, value]);

  useEffect(() => {
    if (isEmpty(userAccount)) {
      return;
    }
    const offlineRequestType =
      userAccount.userPreferences?.offlineRequestType ?? "";
    setValue(offlineRequestType);
  }, [userAccount]);

  useEffect(() => {
    if (!isEmpty(post) && isObject(post) && post.memberId) {
      getAccount({ memberId: post.memberId });
    }
  }, [getAccount, post]);

  const showActions = useMemo(() => {
    const offlineRequestType =
      userAccount.userPreferences?.offlineRequestType ?? "";
    return offlineRequestType !== value;
  }, [userAccount, value]);

  return (
    <Grid className="offline-request-type-form" container direction="column">
      <FormControl>
        {isEmpty(userAccount) ? (
          <CircularProgress size={12} />
        ) : (
          <Select
            variant="outlined"
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
            }}
            fullWidth
          >
            {OFFLINE_REQUEST_TYPES.map((current) => (
              <MenuItem key={current.value} value={current.value}>
                {current.label}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
      {showActions && (
        <Grid container direction="row" justifyContent="flex-end">
          <Button
            variant="contained"
            className="save-btn"
            onClick={onClickSaveBtn}
            disabled={progressing}
          >
            Save
          </Button>
          <Button
            variant="text"
            className="cancel-btn"
            onClick={onCLickCancelBtn}
            disabled={progressing}
          >
            Cancel
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const SearchResultView = ({ post }) => {
  const postAddress = post.postalAddress ?? {};

  return (
    <div className="content p-0">
      <table
        className="table table-hover table-responsive common-table border mb-0"
        style={{ fontSize: 14 }}
      >
        <tbody>
          <tr>
            <td width="125">
              <b>Post ID</b>
            </td>
            <td width="300">{post.id}</td>
          </tr>
          <tr>
            <td width="125">
              <b>Ref Code</b>
            </td>
            <td width="300">{post.offlineId}</td>
          </tr>
          <tr>
            <td width="125">
              <b>Member ID</b>
            </td>
            <td width="300">{post.memberId}</td>
          </tr>
          <tr>
            <td width="125">
              <b>Account Name</b>
            </td>
            <td width="300">
              {post.personalInfo.fname} {post.personalInfo.lname}
            </td>
          </tr>
          <tr>
            <td>
              <b>Address</b>
            </td>
            <td>
              <p className="mb-1">{postAddress.name ?? ""}</p>
              <p className="mb-1">{postAddress.line1 ?? ""}</p>
              <p className="mb-1">{postAddress.line2 ?? ""}</p>
              <p className="mb-1">{postAddress.city ?? ""}</p>
            </td>
          </tr>
          <tr>
            <td>
              <b>Offline Request Type</b>
            </td>
            <td>
              <OfflineRequestTypeForm post={post} memberId={post.memberId} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

function FindAddressesCom() {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchedPost, setSearchedPost] = useState({});

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setSearchedPost({});
      const response = await findPostAddress({ search });
      if (!response.success) {
        return; // TODO: Error Handling
      }
      const responseBody = response.body;
      if (!isEmpty(response.body) && response.body.constructor === Object) {
        setSearchedPost(responseBody);
      }
    } catch (err) {
      // TODO: error handling
      console.log("[Error!] ", err);
    } finally {
      setLoading(false);
    }
  };

  const postAddress = searchedPost.postalAddress ?? {};

  return (
    <div className="content-col">
      <Breadcrumb title="Find Addresses" />

      <div className="row m-0">
        <div className="col p-0">
          <form
            style={{
              display: "block",
              width: "fit-content",
              margin: "auto",
              marginTop: 40,
            }}
          >
            <div className="form-row align-items-center">
              <div className="col-auto">
                <div className="input-group mb-0" style={{ width: "280px" }}>
                  <input
                    type="text"
                    className="form-control pr-5"
                    placeholder="refCode / Post ID"
                    value={search}
                    onChange={onChange}
                    autoComplete="off"
                    style={{ borderRadius: "5px" }}
                  />
                  <button
                    className="btn btn-dark d-block ml-2"
                    onClick={handleSearch}
                    style={{ width: 50 }}
                  >
                    {loading ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <i className="fas fa-search"></i>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="container-fluid inner-content pt-3 pb-4">
        <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
          {loading ? (
            <CircularProgress size={28} style={{ color: "#f6c042" }} />
          ) : !isEmpty(postAddress) ? (
            <SearchResultView post={searchedPost} />
          ) : (
            <p>No Records</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default FindAddressesCom;
