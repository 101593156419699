import Config from "../../../../config";
import { USER_PROFILE_STATUS } from "../../../../static-data";

const { CURRENT_PLATFORM, PLATFORMS } = Config;
const NEW_PRICING_PACKAGES = ["2m_basic", "3m_basic", "3m_pro", "6m_vip"];

export const determinePricingPackages = ({
  adStatus,
  selectedPackage,
  appConfig,
  pricingTier,
}) => {
  if (CURRENT_PLATFORM === PLATFORMS.IN) {
    return false;
  }
  const pricingConfig =
    (Array.isArray(appConfig.appConfig) ? appConfig.appConfig : []).find(
      (ele) => ele.id === "new_pricing"
    ) ?? {};
  const newPricingEnabled = pricingConfig?.enabled ?? false;

  switch (adStatus) {
    case USER_PROFILE_STATUS.PAYMENT_PENDING:
      return newPricingEnabled;
    case USER_PROFILE_STATUS.IN_REVIEW:
      return NEW_PRICING_PACKAGES.includes(selectedPackage);
    case USER_PROFILE_STATUS.LIVE:
    case USER_PROFILE_STATUS.EXPIRED:
      return NEW_PRICING_PACKAGES.includes(pricingTier?.id);
    default:
      return false;
  }
};
