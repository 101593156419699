import React, { useMemo } from "react";
import { Box, Grid } from "@material-ui/core";

import TableHead from "./table-head";
import TableRow from "./table-row";

import "./style.css";
import { flatten, groupBy, isEmpty, sum } from "lodash";
import TableSumRow from "./table-sum-row";

const generateMappingKeys = (input) => {
  const mapping = [
    {
      card: {
        oneTime: input.cardNew.oneTime,
        subscription: input.cardNew.recurring,
        total: input.cardNew.oneTime + input.cardNew.recurring,
      },
      cash: {
        oneTime: input.cashNew,
        subscription: 0,
        total: input.cashNew,
      },
      criteria: "new",
      pricingTier: input.pricingTier,
    },
    {
      card: {
        oneTime: input.cardRenew.oneTime,
        subscription: input.cardRenew.recurring,
        total: input.cardRenew.oneTime + input.cardRenew.recurring,
      },
      cash: {
        oneTime: input.cashRenew,
        subscription: 0,
        total: input.cashRenew,
      },
      criteria: "renew",
      pricingTier: input.pricingTier,
    },
  ];
  return mapping;
};

const PaymentHistoryTable = ({ statistics }) => {
  const tableMapping = useMemo(() => {
    if (isEmpty(statistics) || !Array.isArray(statistics)) {
      return {};
    }
    let output = {};
    const artifact1 = [];
    for (const current of statistics) {
      const mappedObj = generateMappingKeys(current);
      artifact1.push(...mappedObj);
    }
    output = groupBy(artifact1, (current) => current.criteria);
    return output;
  }, [statistics]);

  const verticalTableMapping = useMemo(() => {
    const output = {
      card: { oneTime: 0, subscription: 0 },
      cash: { oneTime: 0, subscription: 0 },
    };
    if (isEmpty(tableMapping)) return output;
    const flattenMappings = flatten(Object.values(tableMapping));
    flattenMappings.forEach((current) => {
      output.card.oneTime = output.card.oneTime + current.card.oneTime;
      output.card.subscription =
        output.card.subscription + current.card.subscription;
      output.cash.oneTime = output.cash.oneTime + current.cash.oneTime;
      output.cash.subscription =
        output.cash.subscription + current.cash.subscription;
    });
    output.total = sum(
      flatten(Object.values(output).map((current) => Object.values(current)))
    );
    return output;
  }, [tableMapping]);

  return (
    <Grid
      container
      direction="column"
      className="payment-history-table-container"
    >
      <TableHead />
      <Box className="table-body">
        {Object.entries(tableMapping).map(([criteria, pricingTiers]) => (
          <TableRow
            key={`${criteria}-${pricingTiers.pricingTier}`}
            criteria={criteria}
            pricingTiers={pricingTiers}
          />
        ))}
        {!isEmpty(verticalTableMapping) && (
          <TableSumRow
            variant="grandTotal"
            data={verticalTableMapping}
            label="Grand Total"
          />
        )}
      </Box>
    </Grid>
  );
};

export default PaymentHistoryTable;
