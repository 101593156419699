const storageKey = "interest-search-filter";
const lastSeenPage = "last-seen-interest-page";

const FiltersContext = {
  getFilterValue: () => {
    return sessionStorage.getItem(storageKey);
  },
  setInterestType: (value) => {
    sessionStorage.setItem(storageKey, value);
  },
  getLastSeenPageNumber: () => {
    return sessionStorage.getItem(lastSeenPage);
  },
  setLastSeenPageNumber: (value) => {
    return sessionStorage.setItem(lastSeenPage, value);
  },
};

export default FiltersContext;
