import React, { useEffect, useState } from "react";
import propTypes from "prop-types";

import Age from "../../../constant/age/index.json"
import Height from "../../../constant/height/index.json"
import { UpdateInterestPrefernce, ResetInterestPreference } from "../../../actions/profile/post";

import {
  Paper,
  Grid,
  CircularProgress,
  Button,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useSelector } from "react-redux";

const EditInterestPreference = ({
  HideEditInterestSection,
  interestPreference,
  postId,
}) => {

  EditInterestPreference.propTypes = {
    HideEditInterestSection: propTypes.func.isRequired,
    interestPreference: propTypes.object.isRequired,
    postId: propTypes.string.isRequired,
  };

  const [minAge, setMinAge] = useState(interestPreference?.minAge ?? null);
  const [maxAge, setMaxAge] = useState(interestPreference?.maxAge ?? null);
  const [heightMin, setHeightMin] = useState(interestPreference?.heightMin ?? null);
  const [heightMax, setHeightMax] = useState(interestPreference?.heightMax ?? null);
  const [ages] = useState(Age);
  const [heights] = useState(Height);
  const [checkedEthnicities, setCheckedEthnicities] = useState(interestPreference?.ethnicities ?? []);
  const [ethnicityAny, setEthnicityAny] = useState(!(interestPreference?.ethnicities.length > 0));
  const [checkedReligions, setCheckedReligions] = useState(interestPreference?.religions ?? []);
  const [religionAny, setReligionAny] = useState(!(interestPreference?.religions.length > 0));
  const [checkedStatus, setCheckedStatus] = useState(interestPreference?.civilStatuses ?? []);
  const [statusAny, setStatusAny] = useState(!(interestPreference?.civilStatuses.length > 0));
  const [checkedResidentCountries, setCheckedResidentCountries] = useState(interestPreference?.residentCountries ?? []);
  const [residentCountriesAny, setResidentCountriesAny] = useState(!(interestPreference?.residentCountries.length > 0));
  const [isSetInterests, setIsSetInterests] = useState(interestPreference ? true : false);
  const [loading, setLoading] = useState(false);
  // const [loadingCancel, setLoadingCancel] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [failAlert, setFailAlert] = useState(false);

  // const { religions, ethnicities, maritalStatuses, residentCountries } =
  //   useSelector((state) => state.genericData);
  const { genericFilters } = useSelector((state) => state.genericDataV2);
  const { religions, residentCountries, maritalStatuses, ethnicities } =
    genericFilters;
  
  useEffect(() => {
    if (minAge > maxAge) {
      setMaxAge(60);
    }
    if (minAge === "") {
      setMinAge(null);
      setMaxAge(null);
    }
  }, [maxAge, minAge])

  useEffect(() => {
    if (minAge > maxAge) {
      setMinAge(18);
    }
    if (maxAge === "") {
      setMaxAge(null);
      setMinAge(null);
    }
    if (minAge === null && maxAge !== null) {
      setMinAge(18);
    }
  }, [maxAge, minAge])

  useEffect(() => {
    if (heightMin > heightMax) {
      setHeightMax(228.6);
    }
    if (heightMin === "") {
      setHeightMin(null);
      setHeightMax(null);
    }
  }, [heightMax, heightMin])

  useEffect(() => {
    if (heightMin > heightMax) {
      setHeightMin(121.92);
    }
    if (heightMax === "") {
      setHeightMin(null);
      setHeightMax(null);
    }
    if (heightMin === null && heightMax !== null) {
      setHeightMin(121.92);
    }
  }, [heightMax, heightMin])

  useEffect(() => {
    if (ethnicityAny) {
      setCheckedEthnicities([]);
    }
  }, [ethnicityAny])

  useEffect(() => {
    if (checkedEthnicities.length > 0) {
      setEthnicityAny(false);
    }
    if (checkedEthnicities.length === 0) {
      setEthnicityAny(true);
    }
  }, [checkedEthnicities])

  useEffect(() => {
    if (religionAny) {
      setCheckedReligions([]);
    }
  }, [religionAny])

  useEffect(() => {
    if (checkedReligions.length > 0) {
      setReligionAny(false);
    }
    if (checkedReligions.length === 0) {
      setReligionAny(true);
    }
  }, [checkedReligions])

  useEffect(() => {
    if (checkedResidentCountries.length > 0) {
      setResidentCountriesAny(false);
    }
    if (checkedResidentCountries.length === 0) {
      setResidentCountriesAny(true);
    }
  }, [checkedResidentCountries])

  useEffect(() => {
    if (residentCountriesAny) {
      setCheckedResidentCountries([]);
    }
  }, [residentCountriesAny])

  useEffect(() => {
    if (checkedStatus.length > 0) {
      setStatusAny(false);
    }
    if (checkedStatus.length === 0) {
      setStatusAny(true);
    }
  }, [checkedStatus])

  useEffect(() => {
    if (statusAny) {
      setCheckedStatus([]);
    }
  }, [statusAny])

  // ethnicity
  const handleEthnicity = (value) => () => {
    const currentIndex = checkedEthnicities.indexOf(value);
    const newChecked = [...checkedEthnicities];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (newChecked.length !== ethnicities?.length) {
      setCheckedEthnicities(newChecked);
    }
    else {
      setEthnicityAny(true);
    }
  };

  const handleEthnicityAny = (value) => {
    if (!value) {
      setEthnicityAny(!value);
    }
  };

  // religion
  const handleReligion = (value) => () => {
    const currentIndex = checkedReligions.indexOf(value);
    const newChecked = [...checkedReligions];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (newChecked.length !== religions?.length) {
      setCheckedReligions(newChecked);
    }
    else {
      setReligionAny(true);
    }
  };

  const handleReligionAny = (value) => {
    if (!value) {
      setReligionAny(!value);
    }
  };

  // civil status
  const handleStatus = (value) => () => {
    const currentIndex = checkedStatus.indexOf(value);
    const newChecked = [...checkedStatus];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (newChecked.length !== maritalStatuses?.length) {
      setCheckedStatus(newChecked);
    }
    else {
      setStatusAny(true);
    }
  };

  const handleStatusAny = (value) => {
    if (!value) {
      setStatusAny(!value);
    }
  };

  // resident country
  const handleResidentCountries = (value) => () => {
    const currentIndex = checkedResidentCountries.indexOf(value);
    const newChecked = [...checkedResidentCountries];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (newChecked.length !== residentCountries?.length) {
      setCheckedResidentCountries(newChecked);
    }
    else {
      setResidentCountriesAny(true);
    }
  };

  const handleResidentCountriesAny = (value) => {
    if (!value) {
      setResidentCountriesAny(!value);
    }
  };

  const handleSave = async () => {
    setLoading(true);

    const data = {
      age:
        minAge && maxAge
          ? { min: Number(minAge), max: Number(maxAge) }
          : {},
      height:
        heightMin && heightMax
          ? { min: Number(heightMin), max: Number(heightMax) }
          : {},
      ethnicities: checkedEthnicities,
      religions: checkedReligions,
      civilStatuses: checkedStatus,
      residentCountries: checkedResidentCountries,
    };

    const response = await UpdateInterestPrefernce(data, postId);

    if (response.status === 200) {
      setSuccessAlert(true);
      setLoading(false);

      setTimeout(() => {
        setSuccessAlert(false);
        handleCancel();
      }, 1300);
    }
    else {
      setLoading(false);
      setFailAlert(true);

      setTimeout(() => {
        setFailAlert(false);
      }, 2000);
    }
  };

  const handleCancel = () => {
    HideEditInterestSection();
    setMinAge(null);
    setMaxAge(null);
    setHeightMin(null);
    setHeightMax(null);
    setCheckedEthnicities([]);
    setEthnicityAny(true);
    setCheckedReligions([]);
    setReligionAny(true);
    setCheckedStatus([]);
    setStatusAny(true);
    setCheckedResidentCountries([]);
    setResidentCountriesAny(true);
    setResetLoading(false);
    setIsSetInterests(false);
  };

  const handleResetPreferences = async () => {
    setResetLoading(true);
    const response = await ResetInterestPreference(postId);

    if (response.status === 200) {
      setMinAge(null);
      setMaxAge(null);
      setHeightMin(null);
      setHeightMax(null);
      setCheckedEthnicities([]);
      setEthnicityAny(true);
      setCheckedReligions([]);
      setReligionAny(true);
      setCheckedStatus([]);
      setStatusAny(true);
      setCheckedResidentCountries([]);
      setResidentCountriesAny(true);
      setResetLoading(false);
      setIsSetInterests(false);
    }
    else {
      setResetLoading(false);
      console.log(response.status);
    }
  };

  const handleClose = () => {
    setSuccessAlert(false);
    setFailAlert(false);
  };

  return (
    <Grid item xs={12}>
      <Paper
        elevation={0}
        className="complete-ad-div settings-container post-details-con"
      >
        <React.Fragment>
          {isSetInterests && (
            <div id="filter-bar-desktop" style={{ position: "relative" }}>
              {resetLoading ? (
                <p className="reset-all-pref" onClick={handleResetPreferences}>
                  <CircularProgress size={15} style={{ color: "#927502" }} />
                </p>
              ) : (
                <p className="reset-all-pref" onClick={handleResetPreferences}>
                  {" "}
                  Reset All{" "}
                </p>
              )}
            </div>
          )}

          <Grid container spacing={1} style={{ marginLeft: "2rem" }}>
            <Grid item xs={12}>
              <p className="field-col" style={{ marginBottom: 0 }}>
                Age Range
              </p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <select
                className="age-range-input"
                name="minAge"
                value={minAge ? minAge : ""}
                onChange={(e) => setMinAge(e.target.value)}
                style={{ width: "80%" }}
              >
                <option key="0" value="">
                  Any
                </option>
                {ages.map((age, i) => (
                  <option key={i} value={age}>
                    {age}
                  </option>
                ))}
              </select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <select
                className="age-range-input"
                name="maxAge"
                value={maxAge ? maxAge : ""}
                onChange={(e) => setMaxAge(e.target.value)}
                style={{ width: "80%" }}
              >
                <option key="0" value="">
                  Any
                </option>
                {ages.map((age, i) => (
                  <option key={i} value={age}>
                    {age}
                  </option>
                ))}
              </select>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            style={{ marginTop: "0.5rem", marginLeft: "2rem" }}
          >
            <Grid item xs={12}>
              <p className="field-col" style={{ marginBottom: 0 }}>
                Height Range
              </p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <select
                className="age-range-input"
                name="heightMin"
                value={heightMin ? heightMin : ""}
                onChange={(e) => setHeightMin(e.target.value)}
                style={{ width: "80%" }}
              >
                <option key="0" value="">
                  Any
                </option>
                {heights.map((height, i) => (
                  <option key={i} value={height.value}>
                    {height.name}
                  </option>
                ))}
              </select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <select
                className="age-range-input"
                name="heightMax"
                value={heightMax ? heightMax : ""}
                onChange={(e) => setHeightMax(e.target.value)}
                style={{ width: "80%" }}
              >
                <option key="0" value="">
                  Any
                </option>
                {heights.map((height, i) => (
                  <option key={i} value={height.value}>
                    {height.name}
                  </option>
                ))}
              </select>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            className="pref-mid-section"
            style={{ marginTop: "1rem", paddingLeft: "2rem" }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <p className="field-col" style={{ marginBottom: 0 }}>
                  Ethnicity
                </p>
              </Grid>
              <Grid item xs={12}>
                {ethnicities?.map((ethnicity, i) => (
                  <button
                    key={i}
                    type="button"
                    className={
                      checkedEthnicities.indexOf(ethnicity.id) !== -1
                        ? "pref-chips active"
                        : "pref-chips"
                    }
                    onClick={handleEthnicity(ethnicity.id)}
                  >
                    {ethnicity.name}{" "}
                    {checkedEthnicities.indexOf(ethnicity.id) !== -1 && (
                      <i className="fas fa-check"></i>
                    )}
                  </button>
                ))}
                <button
                  type="button"
                  className={
                    ethnicityAny ? "pref-chips active" : "pref-chips"
                  }
                  onClick={() => handleEthnicityAny(ethnicityAny)}
                >
                  Any {ethnicityAny && <i className="fas fa-check"></i>}
                </button>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <p className="field-col" style={{ marginBottom: 0 }}>
                  Religion
                </p>
              </Grid>
              <Grid item xs={12}>
                {religions?.map((religion, i) => (
                  <button
                    key={i}
                    type="button"
                    className={
                      checkedReligions.indexOf(religion.id) !== -1
                        ? "pref-chips active"
                        : "pref-chips"
                    }
                    onClick={handleReligion(religion.id)}
                  >
                    {religion.name}{" "}
                    {checkedReligions.indexOf(religion.id) !== -1 && (
                      <i className="fas fa-check"></i>
                    )}
                  </button>
                ))}

                <button
                  type="button"
                  className={
                    religionAny ? "pref-chips active" : "pref-chips"
                  }
                  onClick={() => handleReligionAny(religionAny)}
                >
                  Any {religionAny && <i className="fas fa-check"></i>}
                </button>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <p className="field-col" style={{ marginBottom: 0 }}>
                  Civil Status
                </p>
              </Grid>
              <Grid item xs={12}>
                {maritalStatuses?.map((status, i) => (
                  <button
                    key={i}
                    type="button"
                    className={
                      checkedStatus.indexOf(status.id) !== -1
                        ? "pref-chips active"
                        : "pref-chips"
                    }
                    onClick={handleStatus(status.id)}
                  >
                    {status.status}{" "}
                    {checkedStatus.indexOf(status.id) !== -1 && (
                      <i className="fas fa-check"></i>
                    )}
                  </button>
                ))}

                <button
                  type="button"
                  className={
                    statusAny ? "pref-chips active" : "pref-chips"
                  }
                  onClick={() => handleStatusAny(statusAny)}
                >
                  Any {statusAny && <i className="fas fa-check"></i>}
                </button>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <p className="field-col" style={{ marginBottom: 0 }}>
                  Resident Country
                </p>
              </Grid>
              <Grid item xs={12}>
                {residentCountries?.map((country, i) => (
                  <button
                    key={i}
                    type="button"
                    className={
                      checkedResidentCountries.indexOf(country.code) !==
                        -1
                        ? "pref-chips active"
                        : "pref-chips"
                    }
                    onClick={handleResidentCountries(country.code)}
                  >
                    {country.name}{" "}
                    {checkedResidentCountries.indexOf(country.code) !==
                      -1 && <i className="fas fa-check"></i>}
                  </button>
                ))}

                <button
                  type="button"
                  className={
                    residentCountriesAny
                      ? "pref-chips active"
                      : "pref-chips"
                  }
                  onClick={() =>
                    handleResidentCountriesAny(residentCountriesAny)
                  }
                >
                  Any{" "}
                  {residentCountriesAny && (
                    <i className="fas fa-check"></i>
                  )}
                </button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "2rem" }}>
            <Grid item xs={12}>
              <div className="edit-int-action-btn">
                <Button
                  variant="contained"
                  className="common-cancel-btn"
                  onClick={handleCancel}
                >
                  {/* {loadingCancel ? (
                    <CircularProgress size={25} style={{ color: "#000" }} />
                  ) : ( */}
                    <span>Close</span>
                  {/* )} */}
                </Button>
                <Button
                  variant="contained"
                  className="common-save-btn"
                  onClick={handleSave}
                >
                  {loading ? (
                    <CircularProgress size={25} style={{ color: "#fff" }} />
                  ) : (
                    <span>Save</span>
                  )}
                </Button>
              </div>
            </Grid>
          </Grid>
        </React.Fragment>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={successAlert}
          onClose={handleClose}
        >
          <Alert severity="success">
            Interest Preferences have been changed successfully.
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={failAlert}
          onClose={handleClose}
        >
          <Alert severity="error">Oops, something went wrong.</Alert>
        </Snackbar>
      </Paper>
    </Grid>
  );
};

export default EditInterestPreference;
