import { combineReducers } from "redux";
import authReducer from "./authReducer";
import createAdReducer from "./createAdReducer";
import virtualAdReducer from "./virtualAdReducer";
import genericDataReducer from "./genericData";
import GenericDataReducerV2 from "./genericDataV2";

const rootReducer = combineReducers({
  user: authReducer,
  createAd: createAdReducer,
  virtualAd: virtualAdReducer,
  genericData: genericDataReducer,
  genericDataV2: GenericDataReducerV2,
});

export default rootReducer;
