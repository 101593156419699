export const PLATFORMS = Object.freeze({
  IN: "IN",
  LK: "LK",
});

export const TARGET_ENVIRONMENTS = Object.freeze({
  DEVELOP: "dev",
  PRODUCTION: "prod",
});

export const OTHER_COUNTRY_CODE = "ZZ";
