import {
  SET_APP_CONFIG,
  SET_GENERIC_DATA_V2_INIT_STATES,
} from "../../actions/generic-data";

const initialState = {
  initialized: false,
  pricingPackages: [],
  genericFilters: {},
  appConfig: {},
};

const GenericDataReducerV2 = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_GENERIC_DATA_V2_INIT_STATES:
      return { ...state, ...payload };
    case SET_APP_CONFIG:
      return { ...state, appConfig: payload };
    default:
      return state;
  }
};

export default GenericDataReducerV2;
