import React, { useCallback, useEffect, useState } from "react";
import AdminLayout from "../../components/admin/layouts/AdminLayout";
import { Pagination } from "@material-ui/lab";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import Atoms from "./Component";
import { getVipPackages } from "../../features/generic-data";
import { isEmpty, isObject } from "lodash";
import moment from "moment/moment";

const extractCellValue = (obj, key, defaultValue = "Not Available") => {
  if (!(isObject(obj) && !isEmpty(obj[key]))) return defaultValue;
  const value = obj[key];
  switch (key) {
    case "createdAt":
      const dateFormat = "yyyy-MMM-DD hh:mm A";
      return moment(value).format(dateFormat);
    default:
      return value;
  }
};

const { PageContentLayout, TableHeadCell, StyledTableCell, TableHead } = Atoms;

const ListVipPackagesPage = () => {
  const limit = 10;
  const [paginatedResult, setPaginatedResult] = useState({});
  const [page, setPage] = useState(0);

  useEffect(() => {
    getVipPackages({ page, limit })
      .then((response) => {
        if (response.success) {
          setPaginatedResult(response.body);
        }
      })
      .catch((error) => console.log("[Error!]getVipPackages ", error));
  }, [page]);

  const vipPackages = paginatedResult.results ?? [];

  return (
    <AdminLayout>
      <div className="content-col" style={{ marginBottom: "0", padding: "0" }}>
        <PageContentLayout heading="Vip Requests">
          {!isEmpty(paginatedResult) && (
            <Pagination
              count={paginatedResult.pageCount}
              page={paginatedResult.pageNum + 1}
              onChange={(_event, page) => {
                setPage(page - 1);
              }}
              className="pagi"
            />
          )}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadCell>name</TableHeadCell>
                  <TableHeadCell>email</TableHeadCell>
                  <TableHeadCell>phone number</TableHeadCell>
                  <TableHeadCell>account owner</TableHeadCell>
                  <TableHeadCell>created At</TableHeadCell>
                  <TableHeadCell>status</TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vipPackages.map((currrent) => (
                  <TableRow key={currrent.id}>
                    <StyledTableCell>
                      {extractCellValue(currrent, "name")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {extractCellValue(currrent, "email")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {extractCellValue(currrent, "phoneNumber")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {extractCellValue(currrent, "accountOwner")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {extractCellValue(currrent, "createdAt")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {extractCellValue(currrent, "status")}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PageContentLayout>
      </div>
    </AdminLayout>
  );
};

export default ListVipPackagesPage;
