import React, { useState, useEffect } from "react";
import axios from "axios";

function Parent(props) {
  const [originCountry, setOriginCountry] = useState({
    fOriginCountryCode: "",
    mOriginCountryCode: "",
  });

  const [adData, setAdData] = useState({
    fEthnicityId: "",
    fReligionId: "",
    fProfessionId: "",
    mEthnicityId: "",
    mReligionId: "",
    mProfessionId: "",
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/filters`)
      .then((res) => {
        const filterByFather = res.data.countries.filter(
          (el) => el.code === props.data.post.parentInfo[0].residentCountryCode
        );

        const filterByMather = res.data.countries.filter(
          (el) => el.code === props.data.post.parentInfo[1].residentCountryCode
        );

        setOriginCountry({
          ...originCountry,
          fOriginCountryCode: filterByFather[0].name,
          mOriginCountryCode: filterByMather[0].name,
        });
      })
      .catch((err) => console.log(err));
  }, [props.data.post]);

  useEffect(() => {
    // filtes
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/filters`)
      .then((res) => {
        const sortedReligions = res.data.religions;

        const sortedEthnicities = res.data.ethnicities;

        const sortedPro = res.data.professions;

        // ethnicity
        const fEthnicityId = sortedEthnicities.filter(
          (filter) => filter.id === props.data.post.parentInfo[0].ethnicityId
        );

        const mEthnicityId = sortedEthnicities.filter(
          (filter) => filter.id === props.data.post.parentInfo[1].ethnicityId
        );

        // religion
        const fReligionId = sortedReligions.filter(
          (filter) => filter.id === props.data.post.parentInfo[0].religionId
        );

        const mReligionId = sortedReligions.filter(
          (filter) => filter.id === props.data.post.parentInfo[1].religionId
        );

        // profession
        const fProfessionId = sortedPro.filter(
          (filter) => filter.id === props.data.post.parentInfo[0].professionId
        );

        const mProfessionId = sortedPro.filter(
          (filter) => filter.id === props.data.post.parentInfo[1].professionId
        );

        setAdData({
          ...adData,
          fEthnicityId: fEthnicityId[0]?.name ?? "",
          mEthnicityId: mEthnicityId[0]?.name ?? "",
          fReligionId: fReligionId[0]?.name ?? "",
          mReligionId: mReligionId[0]?.name ?? "",
          fProfessionId: fProfessionId[0]?.name ?? "",
          mProfessionId: mProfessionId[0]?.name ?? "",
        });
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="ad-form-sec">
      <form noValidate>
        <div className="form-row header">
          <div className="form-group col px-sm-2 mb-0">
            <p className="heading mb-0">Parent's Info</p>
          </div>
        </div>
        <div className="content" style={{ paddingTop: 10 }}>
          <p className="form-heading">Father</p>
          <div className="row m-0 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Country of Residence</div>
                <div className="col-7 p-2 font-weight-300">
                  {originCountry.fOriginCountryCode}
                </div>
              </div>
            </div>
            <div className="col-sm p-0 ml-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Ethnicity</div>
                <div className="col-7 p-2 font-weight-300">
                  {adData.fEthnicityId}
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Religion</div>
                <div className="col-7 p-2 font-weight-300">
                  {adData.fReligionId}
                </div>
              </div>
            </div>
            <div className="col-sm p-0 ml-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Caste</div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.post.parentInfo[0].caste
                    ? props.data.post.parentInfo[0].caste
                    : "-"}
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Profession</div>
                <div className="col-7 p-2 font-weight-300">
                  {adData.fProfessionId === "Other"
                    ? props.data.post.parentInfo[0].otherProfession
                    : adData.fProfessionId}
                </div>
              </div>
            </div>
            <div className="col-sm p-0 ml-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Additional Info</div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.post.parentInfo[0].additionalInfo
                    ? props.data.post.parentInfo[0].additionalInfo
                    : "-"}
                </div>
              </div>
            </div>
          </div>

          <p className="form-heading">Mother</p>
          <div className="row m-0 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Country of Residence</div>
                <div className="col-7 p-2 font-weight-300">
                  {originCountry.mOriginCountryCode}
                </div>
              </div>
            </div>
            <div className="col-sm p-0 ml-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Ethnicity</div>
                <div className="col-7 p-2 font-weight-300">
                  {adData.mEthnicityId}
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Religion</div>
                <div className="col-7 p-2 font-weight-300">
                  {adData.mReligionId}
                </div>
              </div>
            </div>
            <div className="col-sm p-0 ml-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Caste</div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.post.parentInfo[1].caste
                    ? props.data.post.parentInfo[1].caste
                    : "-"}
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0 px-2 review-row mb-3">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Profession</div>
                <div className="col-7 p-2 font-weight-300">
                  {adData.mProfessionId === "Other"
                    ? props.data.post.parentInfo[1].otherProfession
                    : adData.mProfessionId}
                </div>
              </div>
            </div>
            <div className="col-sm p-0 ml-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Additional Info</div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.post.parentInfo[1].additionalInfo
                    ? props.data.post.parentInfo[1].additionalInfo
                    : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Parent;
