import EnvVar from "./EnvVar";

import {
  OTHER_COUNTRY_CODE,
  PLATFORMS,
  TARGET_ENVIRONMENTS,
} from "./constant";

const commonConfig = {
  PLATFORMS: PLATFORMS,
  CURRENT_PLATFORM: EnvVar.PLATFORM,
  TARGET_ENVIRONMENTS,
  TARGET_ENVIRONMENT: EnvVar.ENVIRONMENT,
  OTHER_COUNTRY_CODE,
};

const getConfigByPlatform = () => {
  const config = { ...commonConfig };
  switch (EnvVar.PLATFORM) {
    case PLATFORMS.LK:
      config.POST_IMAGE_SLOTS = 3;
      config.HOROSCOPE_IMAGE_SLOTS = 2;
      config.OTHER_PROFESSION_ID = 7;
      break;
    case PLATFORMS.IN:
      config.POST_IMAGE_SLOTS = 5;
      config.HOROSCOPE_IMAGE_SLOTS = 3;
      config.OTHER_PROFESSION_ID = 147;
      break;
    default:
      break;
  }

  return config;
};

const Config = getConfigByPlatform();
export default Config;
