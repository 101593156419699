import React, { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import { isAuthenticatedBoth } from "../../../auth/authBoth";
import { isAdminOrOperatorL2 } from "../../../auth/authAdminOrOperatorL2";
import moment from "moment";
import { Divider } from "@material-ui/core";

function VirtualAgentsKPIsCom() {
  const [allKpi, setAllKpi] = useState([]);
  const [userKpi, setUserKpi] = useState([]);
  const [operators, setOperators] = useState([]);
  const [displayName, setDisplayName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (isAdminOrOperatorL2()) {
      getAllKPI();
    } else {
      getOwnKPI();
    }
  }, []);

  const getOwnKPI = () => {
    setLoading(true);
    if (isAuthenticatedBoth()) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/operator/monthly_stats`, {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        })
        .then((res) => {
          setUserKpi(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const getAllKPI = () => {
    if (isAdminOrOperatorL2()) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/admin/operator_monthly_stats`, {
          headers: {
            Authorization: `Bearer ${isAdminOrOperatorL2()}`,
          },
        })
        .then((res) => {
          let allData = res.data;
          setAllKpi(res.data);
          const keys_to_keep = ['operator'];
          const redux1 = list => list.map(o => Object.fromEntries(
            keys_to_keep.map(k => [k, o[k]])
          ));
          let membersArr = redux1(res.data);
          let uniq = {};
          let arrFiltered = membersArr.filter(obj => !uniq[obj.operator.memberId] && (uniq[obj.operator.memberId] = true));
          setOperators(arrFiltered);
          if (arrFiltered[0]) {
            handleStatus(arrFiltered[0].operator.memberId, arrFiltered[0].operator.name, allData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleStatus = (memberId, name, allData) => {
    setDisplayName(name+" - "+memberId);
    let userKpi = allData.filter(
      (filter) => filter.operator.memberId === memberId
    );
    setUserKpi(userKpi);
    setLoading(false);
  };

  return (
    <>
    <div className="content-col">
      <Breadcrumb mainTitle="virtual Agent" title="KPIs" />
      <div className="container-fluid inner-content py-4">
        <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
          {(!loading && isAdminOrOperatorL2()) &&
            <div className="dropdown">
              <button
                className="btn btn-outline-secondary btn-sm dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  marginLeft: "auto",
                  display: "block",
                  marginBottom: "10px",
                }}
              >
                {displayName}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {
                  operators.map((el, i) => (
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => handleStatus(el.operator.memberId, el.operator.name, allKpi)}
                    >
                      {el.operator.name} - {el.operator.memberId}
                    </button>
                  ))
                }
              </div>
            </div>
          }
          {!loading ?
            <div className="content p-0">
              <table
                className="table table-hover table-responsive common-table border mb-0"
                style={{ fontSize: 14 }}
              >
                <thead className="header">
                  <tr>
                    <th className="border-0">Month</th>
                    <th className="border-0">New Comments</th>
                    <th className="border-0">Expired Comments</th>
                    <th className="border-0">New Conversions</th>
                    <th className="border-0">Renew Conversions</th>
                  </tr>
                </thead>
                <tbody>
                  {userKpi.length ? (
                    userKpi.map((el, i) => (
                      <tr key={i} className="monthly-kpi-row">
                        <td width="150" className="px-2">{moment(el.date).format("YYYY  MMMM")}</td>
                        <td width="160" className="text-center border border-1">{el.numCommentsNew}</td>
                        <td width="160" className="text-center border border-1">{el.numCommentsExpired}</td>
                        <td width="160" className="border border-1 p-0">
                            <div className="d-flex flex-row justify-content-between px-5 py-1">
                              <div>Card</div>
                              <div>
                                {el.numNewConversions}
                              </div>
                            </div>
                            <Divider style={{ width: "100%" }} />
                            <div className="d-flex flex-row justify-content-between px-5 py-1">
                              <div>Cash</div>
                              <div>{el.numNewConversionsCash}</div>
                            </div>
                            <Divider style={{ width: "100%" }} />
                            <div
                              className="d-flex flex-row justify-content-between px-5 py-1 kpi-tot-col"
                            >
                              <div>Total</div>
                              <div>{el.numNewConversions + el.numNewConversionsCash}</div>
                            </div>
                          </td>
                          <td
                            width="160"
                            className="border border-1 p-0"
                          >
                            <div className="d-flex flex-row justify-content-between px-5 py-1">
                              <div>Card</div>
                              <div>
                                {el.numRenewConversions}
                              </div>
                            </div>
                            <Divider style={{ width: "100%" }} />
                            <div className="d-flex flex-row justify-content-between px-5 py-1">
                              <div>Cash</div>
                              <div>{el.numRenewConversionsCash}</div>
                            </div>
                            <Divider style={{ width: "100%" }} />
                            <div
                              className="d-flex flex-row justify-content-between px-5 py-1 kpi-tot-col"
                            >
                              <div>Total</div>
                              <div>{el.numRenewConversions + el.numRenewConversionsCash}</div>
                            </div>
                          </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" width="480">
                        No Records
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            :
            <label>Loading...</label>
          }  
        </div>
      </div>
    </div>
    </>
  );
}

export default VirtualAgentsKPIsCom;
