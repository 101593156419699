import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { NotificationManager } from "react-notifications";

import { USER_PROFILE_STATUS } from "../../../static-data";
import { cancelSubscription } from "../../../features/post";

function CancelSubscriptionModal({
  adDetails,
  modalVisibility,
  setModalVisibility,
}) {
  const [loading, setLoading] = useState(false);

  const handleModalClose = () => {
    setModalVisibility(false);
  };

  const handleCancelSubscription = async () => {
    setLoading(true);
    try {
      const responce = await cancelSubscription({ postId: adDetails.ad?.id });
      if (responce.success) {
        NotificationManager.success("Subscription Cancelled", "Success");
        adDetails.getAd();
      }
    } catch (error) {
      NotificationManager.error("Subscription Cancellation Failed ", "Error");
      console.log("Error >>>>", error);
    } finally {
      setModalVisibility(false);
      setLoading(false);
    }
  };
  const POST_DETAILS_CONFIG = [
    {
      labelName: "Name",
      value: `${adDetails.ad?.personalInfo?.fname ?? ""} ${
        adDetails.ad?.personalInfo?.lname ?? ""
      }`,
    },
    { labelName: "Member Id", value: adDetails.ad?.memberId ?? "" },
    { labelName: "Ad Id", value: adDetails.ad?.id ?? "" },
    {
      labelName: "Package",
      value:
        adDetails.ad?.status === USER_PROFILE_STATUS.LIVE
          ? adDetails.ad?.pricingTier?.id ?? ""
          : adDetails.selectedPackage ?? "",
    },
    { labelName: "Phone", value: adDetails.ad?.phone ?? "" },
  ];
  return (
    <Dialog
      open={modalVisibility}
      onClose={handleModalClose}
      style={{ minWidth: "500px" }}
    >
      <DialogTitle className="modal-header">Are you sure?</DialogTitle>

      <DialogContent style={{ minHeight: 80 }}>
        <Typography variant="h6" style={{ color: "#212529", fontWeight: 400 }}>
          Do you want to cancel this subscription?
        </Typography>
        <List component="nav">
          {POST_DETAILS_CONFIG.map(({ labelName, value }) => (
            <ListItem style={{ padding: 0 }}>
              <ListItemText primary={`${labelName} : ${value}`} />
            </ListItem>
          ))}
        </List>
      </DialogContent>

      <DialogActions>
        <>
          <Button variant="outlined" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="contained" onClick={handleCancelSubscription}>
            {loading ? (
              <CircularProgress color="inherit" size="1rem" thickness={8} />
            ) : (
              "Cancel Subscription"
            )}
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
}

export default CancelSubscriptionModal;
