import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Close from "../../../images/close-white.png";
import { isAuthenticated } from "../../../auth/auth";
import { isAdminOrOperatorL2 } from "../../../auth/authAdminOrOperatorL2";

function SideBar(props) {

  useEffect(() => {
    let element = document.getElementsByClassName("active-tab scrolling-menu");
    if (element[0]) {
      element[0].scrollIntoView();
    }
  }, []);

  const [adsAcco, setAdsAcco] = useState(
    sessionStorage.getItem("adsAcco")
      ? JSON.parse(sessionStorage.getItem("adsAcco"))
      : false
  );
  const [editReqAcco, setEditReqAcco] = useState(
    sessionStorage.getItem("editReqAcco")
      ? JSON.parse(sessionStorage.getItem("editReqAcco"))
      : false
  );
  const [searchAcco, setSearchAcco] = useState(
    sessionStorage.getItem("searchAcco")
      ? JSON.parse(sessionStorage.getItem("searchAcco"))
      : false
  );
  const [virtualAgentAcco, setVirtualAgentAcco] = useState(
    sessionStorage.getItem("virtualAgentAcco")
      ? JSON.parse(sessionStorage.getItem("virtualAgentAcco"))
      : false
  );
  const [followUpsAcco, setFollowUpsAcco] = useState(
    sessionStorage.getItem("followUpsAcco")
      ? JSON.parse(sessionStorage.getItem("followUpsAcco"))
      : false
  );
  const [agentAcco, setAgentAcco] = useState(
    sessionStorage.getItem("agentAcco")
      ? JSON.parse(sessionStorage.getItem("agentAcco"))
      : false
  );
  const [linkAcco, setLinkAcco] = useState(
    sessionStorage.getItem("linkAcco")
      ? JSON.parse(sessionStorage.getItem("linkAcco"))
      : false
  );
  const [phoneNumbersAcco, setPhoneNumbersAcco] = useState(
    sessionStorage.getItem("phoneNumbersAcco")
      ? JSON.parse(sessionStorage.getItem("phoneNumbersAcco"))
      : false
  );
  const [kpiAcco, setKpiAcco] = useState(
    sessionStorage.getItem("kpiAcco")
      ? JSON.parse(sessionStorage.getItem("kpiAcco"))
      : false
  );
  const [reviewsAcco, setReviewsAcco] = useState(
    sessionStorage.getItem("reviewsAcco")
      ? JSON.parse(sessionStorage.getItem("reviewsAcco"))
      : false
  );

  const handleAcco = (name, value, func) => {
    func(value);
    sessionStorage.setItem(name, value);
  };

  const [showAdsMenu, setShowAdsMenu] = useState(false);
  const [showLinksMenu, setShowLinksMenu] = useState(false);
  const [showPhoneNumbersMenu, setShowPhoneNumbersMenu] = useState(false);
  const [showSearchMenu, setShowSearchMenu] = useState(false);
  const [showVirtualAgentMenu, setShowVirtualAgentMenu] = useState(false);
  const [showFollowUpMenu, setShowFollowUpMenu] = useState(false);
  const [showAgentMenu, setShowAgentMenu] = useState(false);
  const [showKpiMenu, setShowKpiMenu] = useState(false);
  const [showReviewsMenu, setShowReviewsMenu] = useState(false);

  const setShowMenu = (func) => {
    setShowAllMenu(false);
    func(true);
  };

  const setShowAllMenu = (val) => {
    setShowAdsMenu(false);
    setShowLinksMenu(false);
    setShowPhoneNumbersMenu(false);
    setShowSearchMenu(false);
    setShowVirtualAgentMenu(false);
    setShowFollowUpMenu(false);
    setShowAgentMenu(false);
    setShowKpiMenu(false);
    setShowReviewsMenu(false);
  }

  return (
    <div className="fixed-div">
      <img
        src={Close}
        className="close-btn"
        alt="close"
        onClick={props.closeDrawer}
      />
      <i className="fas fa-user-circle profile-pic"></i>
      <p className="name">
        Hi {localStorage.getItem("un") ? localStorage.getItem("un") : ""}
      </p>
      <p className="role mb-4" onMouseEnter={() => setShowAllMenu(false)}>
        ({localStorage.getItem("ur") === "ADMIN" ? "Admin" : localStorage.getItem("ur") === "OPERATOR" ? "Operator" : "Operator-L2"})
      </p>

      {/* <div
        className="panel"
        style={{
          borderTop: "1px solid #5d5d5d",
          borderBottom: "1px solid #5d5d5d",
        }}
        onMouseEnter={() => setShowAllMenu(false)}
      >
        <Link
          to="/dashboard"
          className={
            props.history.location.pathname === "/dashboard" ? "active-tab" : ""
          }
          style={{ background: "#14181f" }}
        >
          <i className="fas fa-tachometer-alt mr-2"></i> Dashboard
        </Link>
      </div> */}

      <div
        className={adsAcco ? "accordion active" : "accordion"}
        onClick={() => handleAcco("adsAcco", !adsAcco, setAdsAcco)}
        onMouseEnter={() => setShowMenu(setShowAdsMenu)}
      >
        Action Required
      </div>

      {(showAdsMenu && !adsAcco) &&
        <div className="side-menu-hover-menu"
          onMouseLeave={() => setShowAdsMenu(false)}
        >
          <ul style={{paddingLeft:0}}>
            <Link
              to="/ads/inreview-ads"
              style={{ textDecoration: "none" }}
              onClick={() => handleAcco("adsAcco", !adsAcco, setAdsAcco)}
            >
              In Review
            </Link>
          </ul>
          <ul style={{paddingLeft:0}}>
            <Link
              to="/edit-requests"
              style={{ textDecoration: "none" }}
              onClick={() => handleAcco("adsAcco", !adsAcco, setAdsAcco)}
            >
              Edit Requests
            </Link>
          </ul>
          <ul style={{paddingLeft:0}}>
            <Link
              to="/verify-nic"
              style={{ textDecoration: "none" }}
              onClick={() => handleAcco("adsAcco", !adsAcco, setAdsAcco)}
            >
              NIC Verifications
            </Link>
          </ul>
          {/* <ul style={{paddingLeft:0}}>
            <Link
              to="/all-agent-ads"
              style={{ textDecoration: "none" }}
              onClick={() => handleAcco("adsAcco", !adsAcco, setAdsAcco)}
            >
              All Agent Ads
            </Link>
          </ul> */}
        </div>
      }

      {adsAcco ? (
        <div
          className="panel"
          style={{ borderTop: 0, borderBottom: "1px solid #5d5d5d" }}
          onMouseEnter={() => setShowAllMenu(false)}
        >
          <Link
            to="/ads/inreview-ads"
            className={
              props.history.location.pathname === "/ads/inreview-ads"
                ? "active-tab"
                : ""
            }
            style={{ paddingLeft: 35 }}
          >
            <i className="far fa-edit mr-2"></i> In Review
          </Link>
          <Link
            to="/edit-requests"
            className={
              props.history.location.pathname === "/edit-requests"
                ? "active-tab"
                : ""
            }
            style={{ paddingLeft: 35 }}
          >
            <i className="fas fa-pencil-alt mr-2"></i> Edit Requests
          </Link>
          <Link
            to="/verify-nic"
            className={
              props.history.location.pathname === "/verify-nic"
                ? "active-tab"
                : ""
            }
            style={{ paddingLeft: 35 }}
          >
            <i className="fas fa-user mr-2"></i> NIC Verifications
          </Link>
          {/* <Link
            to="/all-agent-ads"
            className={
              props.history.location.pathname === "/all-agent-ads"
                ? "active-tab"
                : ""
            }
            style={{ paddingLeft: 35 }}
          >
            <i className="far fa-newspaper mr-2"></i> All Agent Ads
          </Link> */}
        </div>
      ) : null}

      <div
        className={searchAcco ? "accordion active" : "accordion"}
        onClick={() =>
          handleAcco("searchAcco", !searchAcco, setSearchAcco)
        }
        onMouseEnter={() => setShowMenu(setShowSearchMenu)}
      >
        Search
      </div>
      {(showSearchMenu && !searchAcco) &&
        <div className="side-menu-hover-menu"
          onMouseLeave={() => setShowSearchMenu(false)}
        >
          <ul style={{paddingLeft:0}}>
            <Link
              to="/ads/all-ads"
              style={{ textDecoration: "none" }}
              onClick={() => handleAcco("searchAcco", !searchAcco, setSearchAcco)}
            >
              Ads
            </Link>
          </ul>
          <ul style={{paddingLeft:0}}>
            <Link
              to="/find-addresses"
              style={{ textDecoration: "none" }}
              onClick={() => handleAcco("searchAcco", !searchAcco, setSearchAcco)}
            >
              Addresses
            </Link>
          </ul>
        </div>
      }

      {searchAcco && (
        <div
          className="panel"
          style={{ borderTop: 0, borderBottom: "1px solid #5d5d5d" }}
          onMouseEnter={() => setShowAllMenu(false)}
        >
          <Link
            to="/ads/all-ads"
            className={
              props.history.location.pathname === "/ads/all-ads"
                ? "active-tab"
                : ""
            }
            style={{ paddingLeft: 35 }}
          >
            <i className="fas fa-search mr-2"></i> Ads
          </Link>
          <Link
            to="/find-addresses"
            className={
              props.history.location.pathname === "/find-addresses"
                ? "active-tab"
                : ""
            }
            style={{ paddingLeft: 35 }}
          >
            <i className="fas fa-map-marker-alt mr-2"></i> Addresses
          </Link>
        </div>
      )}

      <div
        className={virtualAgentAcco ? "accordion active" : "accordion"}
        onClick={() =>
          handleAcco("virtualAgentAcco", !virtualAgentAcco, setVirtualAgentAcco)
        }
        onMouseEnter={() => setShowMenu(setShowVirtualAgentMenu)}
      >
        Virtual Agent
      </div>
      {(showVirtualAgentMenu && !virtualAgentAcco) &&
        <div className="side-menu-hover-menu"
          onMouseLeave={() => setShowVirtualAgentMenu(false)}
        >
          <ul style={{paddingLeft:0}}>
            <Link
              to="/dashboard/virtual-ad-create"
              style={{ textDecoration: "none" }}
              onClick={() =>
                handleAcco("virtualAgentAcco", !virtualAgentAcco, setVirtualAgentAcco)
              }
            >
              Create New Ad
            </Link>
          </ul>
          <ul style={{paddingLeft:0}}>
            <Link
              // to="/dashboard/ads-created-by-vertual-agent"
              to="/all-agent-ads"
              style={{ textDecoration: "none" }}
              onClick={() =>
                handleAcco("virtualAgentAcco", !virtualAgentAcco, setVirtualAgentAcco)
              }
            >
              All Operator Ads
            </Link>
          </ul>
        </div>
      }

      {virtualAgentAcco && (
        <div
          className="panel"
          style={{ borderTop: 0, borderBottom: "1px solid #5d5d5d" }}
          onMouseEnter={() => setShowAllMenu(false)}
        >
          <Link
            to="/dashboard/virtual-ad-create"
            className={
              props.history.location.pathname === "/dashboard/virtual-ad-create"
                ? "active-tab"
                : ""
            }
            style={{ paddingLeft: 35 }}
          >
            <i className="fas fa-plus mr-2"></i> Create New Ad
          </Link>

          <Link
            // to="/dashboard/ads-created-by-vertual-agent"
            to="/all-agent-ads"
            className={
              props.history.location.pathname ===
              "/all-agent-ads"
                ? "active-tab"
                : ""
            }
            style={{ paddingLeft: 35 }}
          >
            <i className="far fa-newspaper mr-2"></i> All Operator Ads
          </Link>
        </div>
      )}

      <div
        className={followUpsAcco ? "accordion active" : "accordion"}
        onClick={() =>
          handleAcco("followUpsAcco", !followUpsAcco, setFollowUpsAcco)
        }
        onMouseEnter={() => setShowMenu(setShowFollowUpMenu)}
      >
        Follow Up
      </div>
      {(showFollowUpMenu && !followUpsAcco) &&
        <div className="side-menu-hover-menu"
          onMouseLeave={() => setShowFollowUpMenu(false)}
        >
          <ul style={{paddingLeft:0}}>
            <Link
              to="/follow-ups/ads-created"
              style={{ textDecoration: "none" }}
              onClick={() =>
                handleAcco("followUpsAcco", !followUpsAcco, setFollowUpsAcco)
              }
            >
              Ad Created
            </Link>
          </ul>
          <ul style={{paddingLeft:0}}>
            <Link
              to="/follow-ups/only-account-created"
              style={{ textDecoration: "none" }}
              onClick={() =>
                handleAcco("followUpsAcco", !followUpsAcco, setFollowUpsAcco)
              }
            >
              User Account Only
            </Link>
          </ul>
          <ul style={{paddingLeft:0}}>
            <Link
              to="/follow-ups/expired-ads"
              style={{ textDecoration: "none" }}
              onClick={() =>
                handleAcco("followUpsAcco", !followUpsAcco, setFollowUpsAcco)
              }
            >
              Expired Ads
            </Link>
          </ul>
        </div>
      }

      {followUpsAcco && (
        <div
          className="panel"
          style={{ borderTop: 0, borderBottom: "1px solid #5d5d5d" }}
          onMouseEnter={() => setShowAllMenu(false)}
        >
          <Link
            to="/follow-ups/ads-created"
            className={
              props.history.location.pathname === "/follow-ups/ads-created"
                ? "active-tab scrolling-menu"
                : ""
            }
            style={{ paddingLeft: 35 }}
          >
            <i className="far fa-newspaper mr-2"></i> Ad Created
          </Link>
          <Link
            to="/follow-ups/only-account-created"
            className={
              props.history.location.pathname ===
              "/follow-ups/only-account-created"
                ? "active-tab scrolling-menu"
                : ""
            }
            style={{ paddingLeft: 35 }}
          >
            <i className="fas fa-user mr-2"></i> User Account Only
          </Link>
          <Link
            to="/follow-ups/expired-ads"
            className={
              props.history.location.pathname ===
              "/follow-ups/expired-ads"
                ? "active-tab scrolling-menu"
                : ""
            }
            style={{ paddingLeft: 35 }}
          >
            <i className="fas fa-user mr-2"></i> Expired Ads
          </Link>
        </div>
      )}

      {/* {isAuthenticated() && (
        <>
          <div
            className={agentAcco ? "accordion active" : "accordion"}
            onClick={() => handleAcco("agentAcco", !agentAcco, setAgentAcco)}
            onMouseEnter={() => setShowMenu(setShowAgentMenu)}
          >
            Agent
          </div>
          {(showAgentMenu && !agentAcco) &&
            <div className="side-menu-hover-menu"
              onMouseLeave={() => setShowAgentMenu(false)}
            >
              <ul style={{paddingLeft:0}}>
                <Link
                  to="/dashboard/create-ad"
                  style={{ textDecoration: "none" }}
                >
                  Create Ad
                </Link>
              </ul>
              <ul style={{paddingLeft:0}}>
                <Link
                  to="/dashboard/agents/new"
                  style={{ textDecoration: "none" }}
                >
                  Pending to Create
                </Link>
              </ul>
              <ul style={{paddingLeft:0}}>
                <Link
                  to="/dashboard/agents/created"
                  style={{ textDecoration: "none" }}
                >
                  All Created Ads
                </Link>
              </ul>
              <ul style={{paddingLeft:0}}>
                <Link
                  to="/dashboard/pending-bank-approvals"
                  style={{ textDecoration: "none" }}
                >
                  Pending Bank Approvals
                </Link>
              </ul>
              <ul style={{paddingLeft:0}}>
                <Link
                  to="/dashboard/create-agent"
                  style={{ textDecoration: "none" }}
                >
                  Register Agent
                </Link>
              </ul>
              <ul style={{paddingLeft:0}}>
                <Link
                  to="/dashboard/all-agents"
                  style={{ textDecoration: "none" }}
                >
                  All Agents
                </Link>
              </ul>
            </div>
          }

          {agentAcco && (
            <div
              className="panel"
              style={{ borderTop: 0, borderBottom: "1px solid #5d5d5d" }}
            >
              <Link
                to="/dashboard/create-ad"
                className={
                  props.history.location.pathname === "/dashboard/create-ad"
                    ? "active-tab"
                    : ""
                }
                style={{ paddingLeft: 35 }}
              >
                <i className="fas fa-plus mr-2"></i> Create Ad
              </Link>
              <Link
                to="/dashboard/agents/new"
                className={
                  props.history.location.pathname === "/dashboard/agents/new"
                    ? "active-tab"
                    : ""
                }
                style={{ paddingLeft: 35 }}
              >
                <i className="far fa-edit mr-2"></i> Pending to Create
              </Link>
              <Link
                to="/dashboard/agents/created"
                className={
                  props.history.location.pathname ===
                  "/dashboard/agents/created"
                    ? "active-tab"
                    : ""
                }
                style={{ paddingLeft: 35 }}
              >
                <i className="far fa-newspaper mr-2"></i> All Created Ads
              </Link>
              <Link
                to="/dashboard/pending-bank-approvals"
                className={
                  props.history.location.pathname ===
                  "/dashboard/pending-bank-approvals"
                    ? "active-tab"
                    : ""
                }
                style={{ paddingLeft: 35 }}
              >
                <i className="fas fa-university mr-2"></i> Pending Bank
                Approvals
              </Link>
              <Link
                to="/dashboard/create-agent"
                className={
                  props.history.location.pathname === "/dashboard/create-agent"
                    ? "active-tab"
                    : ""
                }
                style={{ paddingLeft: 35 }}
              >
                <i className="fas fa-plus mr-2"></i> Register Agent
              </Link>
              <Link
                to="/dashboard/all-agents"
                className={
                  props.history.location.pathname === "/dashboard/all-agents"
                    ? "active-tab"
                    : ""
                }
                style={{ paddingLeft: 35 }}
              >
                <i className="fas fa-users mr-2"></i> All Agents
              </Link>
            </div>
          )}
        </>
      )} */}

      <div
        className={linkAcco ? "accordion active" : "accordion"}
        onClick={() =>
          handleAcco("linkAcco", !linkAcco, setLinkAcco)
        }
        onMouseEnter={() => setShowMenu(setShowLinksMenu)}
      >
        Send SMS
      </div>
      {(showLinksMenu && !linkAcco) &&
        <div className="side-menu-hover-menu"
          onMouseLeave={() => setShowLinksMenu(false)}
        >
          <ul style={{paddingLeft:0}}>
            <Link
              to="/send-pay-link"
              style={{ textDecoration: "none" }}
              onClick={() =>
                handleAcco("linkAcco", !linkAcco, setLinkAcco)
              }
            >
              Send Pay Link
            </Link>
          </ul>
          <ul style={{paddingLeft:0}}>
            <Link
              to="/recent-sms"
              style={{ textDecoration: "none" }}
              onClick={() =>
                handleAcco("linkAcco", !linkAcco, setLinkAcco)
              }
            >
              Search SMS
            </Link>
          </ul>
          <ul style={{paddingLeft:0}}>
            <Link
              to="send-bank-details"
              style={{ textDecoration: "none" }}
              onClick={() =>
                handleAcco("linkAcco", !linkAcco, setLinkAcco)
              }
            >
              Send Bank Details SMS
            </Link>
          </ul>
          <ul style={{paddingLeft:0}}>
            <Link
              to="send-sms"
              style={{ textDecoration: "none" }}
              onClick={() =>
                handleAcco("linkAcco", !linkAcco, setLinkAcco)
              }
            >
              Send Generic SMS
            </Link>
          </ul>
        </div>
      }
      {linkAcco && (
        <div
          className="panel"
          style={{ borderTop: 0, borderBottom: "1px solid #5d5d5d" }}
        >
          {isAdminOrOperatorL2() && (
            <Link
              to="/send-pay-link"
              className={
                props.history.location.pathname === "/send-pay-link"
                  ? "active-tab scrolling-menu"
                  : ""
              }
              style={{ paddingLeft: 35 }}
            >
              <i className="fas fa-paper-plane  mr-2"></i> Send Pay Link
            </Link>
          )}
          {isAdminOrOperatorL2() &&
              <Link
              to="/recent-sms"
              style={{ paddingLeft: 35 }}
              className={
                props.history.location.pathname === "/recent-sms"
                  ? "active-tab scrolling-menu"
                  : ""
              }
              >
                <i className="fas fa-paper-plane  mr-2"></i> Search SMS
            </Link>
          }
          <Link
            to="/send-bank-details"
            className={
              props.history.location.pathname === "/send-bank-details"
                ? "active-tab scrolling-menu"
                : ""
            }
            style={{ paddingLeft: 35 }}
          >
            <i className="fas fa-paper-plane  mr-2"></i> Send Bank Details SMS
          </Link>
          <Link
            to="/send-sms"
            className={
              props.history.location.pathname === "/send-sms"
                ? "active-tab scrolling-menu"
                : ""
            }
            style={{ paddingLeft: 35 }}
          >
            <i className="fas fa-paper-plane  mr-2"></i> Send Generic SMS
          </Link>
        </div>
      )}

      <div
        className={phoneNumbersAcco ? "accordion active" : "accordion"}
        onClick={() =>
          handleAcco("phoneNumbersAcco", !phoneNumbersAcco, setPhoneNumbersAcco)
        }
        onMouseEnter={() => setShowMenu(setShowPhoneNumbersMenu)}
      >
        Phone Numbers
      </div>
      {(showPhoneNumbersMenu && !phoneNumbersAcco) &&
        <div className="side-menu-hover-menu"
          onMouseLeave={() => setShowPhoneNumbersMenu(false)}
        >
          <ul style={{paddingLeft:0}}>
            <Link
              to="/verify-phone-number"
              style={{ textDecoration: "none" }}
              onClick={() =>
                handleAcco("phoneNumbersAcco", !phoneNumbersAcco, setPhoneNumbersAcco)
              }
            >
              Verify Number
            </Link>
          </ul>
          {isAdminOrOperatorL2() &&
            <ul style={{paddingLeft:0}}>
              <Link
                to="/phone-numbers-blacklist"
                style={{ textDecoration: "none" }}
                onClick={() =>
                  handleAcco("phoneNumbersAcco", !phoneNumbersAcco, setPhoneNumbersAcco)
                }
              >
                Blacklist
              </Link>
            </ul>
          }
        </div>
      }
      {phoneNumbersAcco && (
        <div
          className="panel"
          style={{ borderTop: 0, borderBottom: "1px solid #5d5d5d" }}
        >
          <Link
            to="/verify-phone-number"
            className={
              props.history.location.pathname === "/verify-phone-number"
                ? "active-tab scrolling-menu"
                : ""
            }
            style={{ paddingLeft: 35 }}
          >
            <i className="fas fa-paper-plane  mr-2"></i> Verify Number
          </Link>
          <Link
            to="/phone-numbers-blacklist"
            className={
              props.history.location.pathname === "/phone-numbers-blacklist"
                ? "active-tab scrolling-menu"
                : ""
            }
            style={{ paddingLeft: 35 }}
          >
            <i className="fas fa-paper-plane  mr-2"></i> Blacklist
          </Link>
        </div>
      )}

      <div
        className={kpiAcco ? "accordion active" : "accordion"}
        onClick={() =>
          handleAcco("kpiAcco", !kpiAcco, setKpiAcco)
        }
        onMouseEnter={() => setShowMenu(setShowKpiMenu)}
      >
        KPI
      </div>
      {(showKpiMenu && !kpiAcco) &&
        <div className="side-menu-hover-menu"
          onMouseLeave={() => setShowKpiMenu(false)}
        >
          <ul style={{paddingLeft:0}}>
            <Link
              to="/kpi"
              style={{ textDecoration: "none" }}
              onClick={() =>
                handleAcco("kpiAcco", !kpiAcco, setKpiAcco)
              }
            >
              Monthly KPI
            </Link>
          </ul>
          {isAdminOrOperatorL2() &&
            <ul style={{paddingLeft:0}}>
              <Link
                to="/dashboard/vertual-agents/kpi"
                style={{ textDecoration: "none" }}
                onClick={() =>
                  handleAcco("kpiAcco", !kpiAcco, setKpiAcco)
                }
              >
                Realtime KPI
              </Link>
            </ul>
          }
        </div>
      }
      {kpiAcco && (
        <div
          className="panel"
          style={{ borderTop: 0, borderBottom: "1px solid #5d5d5d" }}
        >
          <Link
            to="/kpi"
            className={
              props.history.location.pathname === "/kpi"
                ? "active-tab scrolling-menu"
                : ""
            }
            style={{ paddingLeft: 35 }}
          >
            <i className="fas fa-paper-plane  mr-2"></i> Monthly KPI
          </Link>
          <Link
            to="/dashboard/vertual-agents/kpi"
            className={
              props.history.location.pathname === "/dashboard/vertual-agents/kpi"
                ? "active-tab scrolling-menu"
                : ""
            }
            style={{ paddingLeft: 35 }}
          >
            <i className="fas fa-paper-plane  mr-2"></i> Realtime KPI
          </Link>
        </div>
      )}

      {/* <div className="panel" style={{ borderBottom: "1px solid #5d5d5d" }} onMouseEnter={() => setShowAllMenu(false)}>
        <Link
          to="/verify-phone-number"
          className={
            props.history.location.pathname === "/verify-phone-number"
              ? "active-tab"
              : ""
          }
          style={{ background: "#14181f" }}
        >
          <i className="fas fa-phone fa-rotate-90 mr-2"></i> Verify Phone Numbers
        </Link>
      </div> */}
      {/* <div className="panel" style={{ borderBottom: "1px solid #5d5d5d" }} onMouseEnter={() => setShowAllMenu(false)}>
        <Link
          to="/kpi"
          className={
            props.history.location.pathname ===
            "/kpi"
              ? "active-tab scrolling-menu"
              : ""
          }
          style={{ background: "#14181f" }}
        >
          <i className="far fa-newspaper mr-2"></i> Operator KPI
        </Link>
      </div> */}

      <div
        className={reviewsAcco ? "accordion active" : "accordion"}
        onClick={() =>
          handleAcco("reviewsAcco", !reviewsAcco, setReviewsAcco)
        }
        onMouseEnter={() => setShowMenu(setShowReviewsMenu)}
      >
        Reviews
      </div>
      {(showReviewsMenu && !reviewsAcco) &&
        <div className="side-menu-hover-menu"
          onMouseLeave={() => setShowReviewsMenu(false)}
        >
          <ul style={{paddingLeft:0}}>
            <Link
              to="/add-review"
              style={{ textDecoration: "none" }}
              onClick={() =>
                handleAcco("reviewsAcco", !reviewsAcco, setReviewsAcco)
              }
            >
              Add Review
            </Link>
          </ul>
        </div>
      }
      {reviewsAcco && (
        <div
          className="panel"
          style={{ borderTop: 0, borderBottom: "1px solid #5d5d5d" }}
        >
          <Link
            to="/add-review"
            className={
              props.history.location.pathname === "/add-review"
                ? "active-tab scrolling-menu"
                : ""
            }
            style={{ paddingLeft: 35 }}
          >
            <i className="fas fa-paper-plane  mr-2"></i> Add Review
          </Link>
          <Link
            to="/user-reviews"
            className={
              props.history.location.pathname === "/user-reviews"
                ? "active-tab scrolling-menu"
                : ""
            }
            style={{ paddingLeft: 35 }}
          >
            <i className="fas fa-paper-plane  mr-2"></i> User Reviews
          </Link>
          <Link
            to="/unpublish-reviews"
            className={
              props.history.location.pathname === "/unpublish-reviews"
                ? "active-tab scrolling-menu"
                : ""
            }
            style={{ paddingLeft: 35 }}
          >
            <i className="fas fa-trash-restore mr-2"></i> Unpublish Reviews
          </Link>
        </div>
      )}


      <div className="panel" style={{ borderBottom: "1px solid #5d5d5d" }} onMouseEnter={() => setShowAllMenu(false)}>
        <Link
          to="/settings"
          className={
            props.history.location.pathname === "/settings" ? "active-tab scrolling-menu" : ""
          }
          style={{ background: "#14181f" }}
        >
          <i className="fas fa-cog mr-2"></i> Settings
        </Link>
      </div>

      <div className="panel" style={{ borderBottom: "1px solid #5d5d5d" }} onMouseEnter={() => setShowAllMenu(false)}>
        <Link
          to="/stat-dashboard"
          className={
            props.history.location.pathname === "/stat-dashboard" ? "active-tab scrolling-menu" : ""
          }
          style={{ background: "#14181f" }}
        >
          <i className="fas fa-desktop mr-2"></i> Stat Dashboard
        </Link>
      </div>
      <div className="panel" style={{ borderBottom: "1px solid #5d5d5d" }} onMouseEnter={() => setShowAllMenu(false)}>
        <Link
          to="/vip-packages"
          className={
            props.history.location.pathname === "/vip-packages" ? "active-tab scrolling-menu" : ""
          }
          style={{ background: "#14181f" }}
        >
          <i className="fas fa-briefcase mr-2"></i> Vip Packages
        </Link>
      </div>
      {/* {isAuthenticated() && (
        <div className="panel" style={{ borderBottom: "1px solid #5d5d5d" }} onMouseEnter={() => setShowAllMenu(false)}>
          <Link
            to="/dashboard/vertual-agents/kpi"
            className={
              props.history.location.pathname ===
              "/dashboard/vertual-agents/kpi"
                ? "active-tab"
                : ""
            }
            style={{ background: "#14181f" }}
          >
            <i className="far fa-newspaper mr-2"></i> KPIs
          </Link>
        </div>
      )} */}
      {/* {isAdminOrOperatorL2() && (
        <div className="panel" style={{ borderBottom: "1px solid #5d5d5d" }} onMouseEnter={() => setShowAllMenu(false)}>
          <Link
            to="/phone-numbers-blacklist"
            className={
              props.history.location.pathname ===
              "/phone-numbers-blacklist"
                ? "active-tab"
                : ""
            }
            style={{ background: "#14181f" }}
          >
            <i className="far fa-newspaper mr-2"></i> Phone Numbers Blacklist
          </Link>
        </div>
      )} */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isEmptyPost:
      Object.keys(state.user.userData).length === 0 &&
      state.user.userData.constructor === Object,
    user: state.user.userData,
  };
};

export default connect(mapStateToProps, null)(withRouter(SideBar));
