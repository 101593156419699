import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty, isNull } from "lodash";
import { TextField } from "@material-ui/core";
import { format } from "date-fns-tz";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Grid } from "@material-ui/core";
import DatePicker from "../../../base/date-picker";
import moment from "moment";
import PaymentHistoryTable from "./payment-hisory-table";
import DashboardWidgetService from "../../../../services/dashboard-widgets";

const styles = makeStyles({
  disabledButton: {
    backgroundColor: "grey !important",
  },
});

const formatDate = (date) => {
  const formattedDate = format(date, "yyyy-MM-dd", {
    timeZone: "Asia/Colombo",
  });
  return formattedDate;
};

const PaymentHistoryWidget = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [statistics, setStatistics] = useState([]);

  const onClickShowResultBtn = useCallback(async () => {
    try {
      if (isNull(fromDate) || isNull(toDate)) {
        return;
      }
      const apiResponse =
        await DashboardWidgetService.getPaymentHistoryBreakdown({
          startDate: formatDate(fromDate),
          endDate: formatDate(toDate),
          promoCode: promoCode,
        });
      if (!apiResponse.success) {
        throw new Error("API ERROR: unable to retrieve payment history data");
      }
      let statData = apiResponse.body?.pricingTiers;
      if (Array.isArray(statData)) {
        statData = statData.filter((current) => !isEmpty(current.pricingTier));
      }
      setStatistics(Array.isArray(statData) ? statData : []);
    } catch (error) {
      // TODO: Error Handling
      console.error(error);
    }
  }, [fromDate, promoCode, toDate]);

  const onChangePromoCodes = (e) => {
    setPromoCode(e.target.value);
  };

  return (
    <div className="content-col" style={{ paddingBottom: 10 }}>
      <div
        className="container-fluid inner-content"
        style={{ paddingBottom: 0 }}
      >
        <div
          className="ad-form-sec"
          style={{ maxWidth: "100%", marginBottom: "0" }}
        >
          <form noValidate>
            <div className="form-row header">
              <div className="form-group col px-sm-2 mb-0">
                <p className="heading mb-0">Payment History</p>
              </div>
            </div>
            <div className="content" style={{ paddingBottom: 25 }}>
              <Grid
                container
                direction="row"
                className="widget-filter-container"
              >
                <DatePicker
                  label="from"
                  selectedDate={
                    toDate
                      ? moment(fromDate).isBefore(toDate) ||
                        moment(fromDate).isSame(toDate)
                        ? fromDate
                        : null
                      : fromDate
                  }
                  onChangeDate={(date) => {
                    setFromDate(date);
                  }}
                />
                <DatePicker
                  label="to"
                  selectedDate={toDate}
                  onChangeDate={(date) => {
                    setToDate(date);
                  }}
                />
                <TextField
                  variant="outlined"
                  label="Add promo code"
                  size="small"
                  onChange={onChangePromoCodes}
                />
                <Button
                  className="widget-filter-btn"
                  onClick={onClickShowResultBtn}
                  disabled={
                    !moment(fromDate).isBefore(toDate) &&
                    !moment(fromDate).isSame(toDate)
                  }
                  classes={{ disabled: styles.disabledButton }}
                >
                  Show Results
                </Button>
              </Grid>
              {!isEmpty(statistics) && (
                <PaymentHistoryTable statistics={statistics} />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistoryWidget;
