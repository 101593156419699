import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

import { Paper, Grid, Typography } from "@material-ui/core";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";

import EditApproveModal from "../../modals/EditApproveModal";
import EditRejectModal from "../../modals/EditRejectModal";

import { isAuthenticated } from "../../../../auth/auth";
import { isAuthenticatedBoth } from "../../../../auth/authBoth";
import { isAdminOrOperatorL2 } from "../../../../auth/authAdminOrOperatorL2";
import Config from "../../../../config";

const { CURRENT_PLATFORM, PLATFORMS } = Config;

const PendingEdits = (props) => {
  const [fatherDesc, setFatherDesc] = useState("");
  const [motherDesc, setMotherDesc] = useState("");
  const [imageData, setImageData] = useState([]);
  const [imageHoroData, setImageHoroData] = useState([]);

  useEffect(() => {
    const parentDescriptions =
      props.ad.pendingEditRequests[0].parentDescriptions;

    parentDescriptions.forEach((parent) => {
      let filter = props.ad.parentInfo.filter((el) => el.id === parent.id);

      if (filter.length > 0) {
        if (filter[0].type === "FATHER") {
          setFatherDesc(parent.description);
        }
        if (filter[0].type === "MOTHER") {
          setMotherDesc(parent.description);
        }
      }
    });

    getHoroPhotos();
  }, []);

  useEffect(() => {
    getProfilePhotos();
  }, [props.ad.pendingEditRequests[0].images]);
  const getProfilePhotos = () => {
    if (
      props.ad.pendingEditRequests[0].images &&
      props.ad.pendingEditRequests[0].images.length
    ) {
      for (let i = 0; i < props.ad.pendingEditRequests[0].images.length; i++) {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/v1/account/posts/${props.ad.id}/pending_images/${props.ad.pendingEditRequests[0].images[i]}?width=500&height=500`,
            {
              headers: {
                Authorization: `Bearer ${isAuthenticatedBoth()}`,
              },
              responseType: "blob",
            }
          )
          .then((res) => {
            let data = URL.createObjectURL(res.data);

            setImageData((imageData) => [...imageData, data]);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const getHoroPhotos = () => {
    if (
      props.ad.pendingEditRequests[0].horoscopeImages &&
      props.ad.pendingEditRequests[0].horoscopeImages.length
    ) {
      for (
        let i = 0;
        i < props.ad.pendingEditRequests[0].horoscopeImages.length;
        i++
      ) {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/v1/account/posts/${props.ad.id}/pending_images/${props.ad.pendingEditRequests[0].horoscopeImages[i]}?width=500&height=500`,
            {
              headers: {
                Authorization: `Bearer ${isAuthenticatedBoth()}`,
              },
              responseType: "blob",
            }
          )
          .then((res) => {
            let data = URL.createObjectURL(res.data);

            setImageHoroData((imageHoroData) => [...imageHoroData, data]);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Paper elevation={0} className="post-details-con">
          <Typography variant="h6" className="heading">
            Pending Edits
          </Typography>
          <p style={{ fontSize: 12, marginBottom: 0, color: "#7e7e7e" }}>
            {moment
              .utc(props.ad.pendingEditRequests[0].createdAt)
              .local()
              .format("DD-MMM-YYYY hh:mm a")}
          </p>
          <div className="row m-0">
            <div className="col p-0">
            <p className="review-sub-sec-heading">
                First Name
              </p>

              <div className="review-col w-100 p-0">
                <div className="con-row">
                  <div
                    className="con-col w-100"
                    style={{ textAlign: "left", fontWeight: 400 }}
                  >
                    {props.ad.pendingEditRequests[0].firstName
                      ? props.ad.pendingEditRequests[0].firstName
                      : "-"}
                  </div>
                </div>
              </div>

              <p className="review-sub-sec-heading">
                Last Name
              </p>

              <div className="review-col w-100 p-0">
                <div className="con-row">
                  <div
                    className="con-col w-100"
                    style={{ textAlign: "left", fontWeight: 400 }}
                  >
                    {props.ad.pendingEditRequests[0].lastName
                      ? props.ad.pendingEditRequests[0].lastName
                      : "-"}
                  </div>
                </div>
              </div>

              {CURRENT_PLATFORM === PLATFORMS.LK && (
                <>
                  <p className="review-sub-sec-heading">Caste</p>

                  <div className="review-col w-100 p-0">
                    <div className="con-row">
                      <div
                        className="con-col w-100"
                        style={{ textAlign: "left", fontWeight: 400 }}
                      >
                        {props.ad.pendingEditRequests[0].caste || "-"}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {CURRENT_PLATFORM === PLATFORMS.IN && (
                <>
                  <p className="review-sub-sec-heading">Sub Community</p>

                  <div className="review-col w-100 p-0">
                    <div className="con-row">
                      <div
                        className="con-col w-100"
                        style={{ textAlign: "left", fontWeight: 400 }}
                      >
                        {props.ad.pendingEditRequests[0].subCommunity || "-"}
                      </div>
                    </div>
                  </div>
                </>
              )}

              <p className="review-sub-sec-heading">Ad Description</p>

              <div className="review-col w-100 p-0">
                <div className="con-row">
                  <div
                    className="con-col w-100"
                    style={{ textAlign: "left", fontWeight: 400 }}
                  >
                    {props.ad.pendingEditRequests[0].additionalInfo
                      ? props.ad.pendingEditRequests[0].additionalInfo
                      : "-"}
                  </div>
                </div>
              </div>

              <p className="review-sub-sec-heading">
                Additional Information - Father
              </p>

              <div className="review-col w-100 p-0">
                <div className="con-row">
                  <div
                    className="con-col w-100"
                    style={{ textAlign: "left", fontWeight: 400 }}
                  >
                    {fatherDesc ? fatherDesc : "-"}
                  </div>
                </div>
              </div>

              <p className="review-sub-sec-heading">
                Additional Information - Mother
              </p>

              <div className="review-col w-100 p-0">
                <div className="con-row">
                  <div
                    className="con-col w-100"
                    style={{ textAlign: "left", fontWeight: 400 }}
                  >
                    {motherDesc ? motherDesc : "-"}
                  </div>
                </div>
              </div>
              {imageData.length > 0 && (
                <>
                  <p className="review-sub-sec-heading">Your Photos</p>

                  <PhotoProvider>
                    {imageData &&
                      imageData.map((item, index) => (
                        <PhotoConsumer key={index} src={item} intro={item}>
                          <img
                            src={item}
                            alt="horescope images"
                            className="photos"
                            style={{ marginTop: 10 }}
                          />
                        </PhotoConsumer>
                      ))}
                  </PhotoProvider>
                </>
              )}

              {imageHoroData.length > 0 && (
                <>
                  <p className="review-sub-sec-heading">Horoscope Images</p>

                  <PhotoProvider>
                    {imageHoroData &&
                      imageHoroData.map((item, index) => (
                        <PhotoConsumer key={index} src={item} intro={item}>
                          <img
                            src={item}
                            alt="horescope images"
                            className="photos"
                            style={{ marginTop: 10 }}
                          />
                        </PhotoConsumer>
                      ))}
                  </PhotoProvider>
                </>
              )}

              <p className="review-sub-sec-heading">
                Additional Horoscope Information
              </p>

              <div className="review-col w-100 p-0">
                <div className="con-row">
                  <div
                    className="con-col w-100"
                    style={{ textAlign: "left", fontWeight: 400 }}
                  >
                    {props.ad.pendingEditRequests[0].horoscopeDetail
                      ? props.ad.pendingEditRequests[0].horoscopeDetail
                      : "-"}
                  </div>
                </div>
              </div>

              {isAdminOrOperatorL2() && (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-danger mt-4 ml-2"
                    data-toggle="modal"
                    data-target="#editRejectModal"
                    style={{
                      width: "100px",
                      float: "right",
                    }}
                  >
                    Reject
                  </button>
                  <button
                    type="button"
                    className="btn btn-success mt-4"
                    data-toggle="modal"
                    data-target="#editApproveModal"
                    style={{ width: 100, float: "right" }}
                  >
                    Accept
                  </button>
                </>
              )}
            </div>
          </div>
        </Paper>
      </Grid>

      <EditApproveModal
        id={props.ad.pendingEditRequests[0].id}
        loading={props.loading}
        handleEditReq={props.handleEditReq}
      />

      <EditRejectModal
        id={props.ad.pendingEditRequests[0].id}
        loading={props.loading}
        handleEditReq={props.handleEditReq}
      />
    </>
  );
};

export default PendingEdits;
