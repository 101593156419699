import { Grid, Typography } from "@material-ui/core";
import { flatten, sum } from "lodash";
import React, { useMemo } from "react";
import TableSumRow from "../table-sum-row";

const TableRow = ({ criteria, pricingTiers }) => {

  const verticalTableMapping = useMemo(() => {
    const output = {
      card: { oneTime: 0, subscription: 0 },
      cash: { oneTime: 0, subscription: 0 },
    };
    if (Array.isArray(pricingTiers)) {
      pricingTiers.forEach((current) => {
        output.card.oneTime = output.card.oneTime + current.card.oneTime;
        output.card.subscription =
          output.card.subscription + current.card.subscription;
        output.cash.oneTime = output.cash.oneTime + current.cash.oneTime;
        output.cash.subscription =
          output.cash.subscription + current.cash.subscription;
      });
    }
    output.total = sum(
      flatten(Object.values(output).map((current) => Object.values(current)))
    );
    return output;
  }, [pricingTiers]);

  return (
    <Grid container direction="row" className="table-row table-data-row">
      <Grid
        item
        xs={2}
        container
        className="crieria-cell justify-center items-center"
      >
        <Typography>{criteria}</Typography>
      </Grid>
      <Grid item xs container direction="column">
        {pricingTiers.map((current) => {
          const horizontalTotal = current.card.total + current.cash.total;
          return (
            <Grid
              item
              container
              className="table-stat-row justify-center items-center"
            >
              <Grid item xs={2} className="outer-cell">
                <Typography>{current.pricingTier}</Typography>
              </Grid>
              <Grid
                item
                xs={5}
                container
                direction="row"
                className="justify-center items-center outer-cell divided-cell"
              >
                <Typography className="flex-1 w-full">
                  {current.card.oneTime}
                </Typography>
                <Typography className="flex-1 w-full">
                  {current.card.subscription}
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                direction="row"
                className="justify-center items-center outer-cell"
              >
                <Typography className="flex-1 w-full">
                  {current.cash.oneTime}
                </Typography>
              </Grid>
              <Grid item xs container className="justify-center items-center">
                <Typography className="flex-1 w-full text-bolder">
                  {horizontalTotal}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
        <TableSumRow
          variant="subTotal"
          data={verticalTableMapping}
          label="SubTotal"
        />
      </Grid>
    </Grid>
  );
};

export default TableRow;
