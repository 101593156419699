import { isEmpty } from "lodash";
import httpAdapter, { ApiVersion, HttpMethod } from "../../utils/http-adaper";

const DashboardWidgetService = {
  getPaymentHistoryBreakdown: async ({ startDate, endDate, promoCode }) => {
    const queryParams = {
      from: startDate,
      to: endDate,
    };
    if (!isEmpty(promoCode)) {
      queryParams.promoCode = promoCode;
    }
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: "admin/payment_stats",
      authneticated: true,
      queryParams,
    });
  },
};

export default DashboardWidgetService;
